.club-card {
  background-color: var(--themewhite-02);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 360px;
  height: 104px;
  display: flex;
  position: relative;
  box-shadow: 0 2px 8px #000e140d;
}

.club-card .club-card-content {
  background-color: var(--themewhite);
  flex: 1;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 20px;
  display: flex;
  position: relative;
}

.club-card .image-wrapper {
  justify-content: flex-end;
  align-items: center;
  width: 64px;
  height: 64px;
  padding: 5px;
  display: flex;
  position: relative;
}

.club-card .image-6 {
  background-position: 50%;
  background-size: cover;
  flex: 1;
  align-self: stretch;
  position: relative;
}

.club-card .club-card-content-2 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
  padding: 0 5px;
  display: inline-flex;
  position: relative;
}

.club-card .club-card-content-3 {
  color: var(--themeswamp);
  font-family: var(--theme-inter-bold-15-47-font-family);
  font-size: var(--theme-inter-bold-15-47-font-size);
  font-style: var(--theme-inter-bold-15-47-font-style);
  font-weight: var(--theme-inter-bold-15-47-font-weight);
  letter-spacing: var(--theme-inter-bold-15-47-letter-spacing);
  line-height: var(--theme-inter-bold-15-47-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.club-card .club-card-content-4 {
  color: var(--themeoslo-gray);
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  position: relative;
}

.footer {
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: 0;
  width: 100%;
  display: flex;
  position: relative;
}

.footer .footer-content {
  background-color: var(--themeshark);
  flex-wrap: wrap;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  padding: 0 33px;
  display: flex;
  position: relative;
}

.footer .footer-content-2 {
  flex-wrap: wrap;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  gap: 0;
  max-width: 1200px;
  display: flex;
  position: relative;
}

.footer .footer-content-3 {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 32px;
  min-width: 320px;
  padding: 32px 0;
  display: flex;
  position: relative;
}

.footer .footer-content-4 {
  width: 186.97px;
  height: 68.61px;
  position: relative;
}

.footer .frame {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  display: flex;
  position: relative;
}

.footer .footer-content-5 {
  flex-flow: column wrap;
  flex: 1;
  align-items: flex-start;
  gap: 0;
  max-width: 500px;
  display: flex;
  position: relative;
}

.footer .item {
  flex-direction: column;
  align-items: flex-start;
  width: 140px;
  height: 32px;
  display: flex;
  position: relative;
}

.footer .link-2 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.footer .text-wrapper-3 {
  color: var(--themeiron);
  letter-spacing: 0;
  flex: 1;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 11.6px;
  font-weight: 400;
  line-height: 32px;
  position: relative;
}

.footer .text-wrapper-4 {
  color: var(--themeiron);
  letter-spacing: 0;
  flex: 1;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 11.4px;
  font-weight: 400;
  line-height: 32px;
  position: relative;
}

.footer .text-wrapper-5 {
  color: var(--themeiron);
  letter-spacing: 0;
  flex: 1;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 11.2px;
  font-weight: 400;
  line-height: 32px;
  position: relative;
}

.footer .text-wrapper-6 {
  color: var(--themeiron);
  letter-spacing: 0;
  flex: 1;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: 32px;
  position: relative;
}

.footer .link-3 {
  flex: none;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.footer .text-wrapper-7 {
  color: var(--themeiron);
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 11.6px;
  font-weight: 400;
  line-height: 32px;
  position: relative;
}

.footer .sponsors-2 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 5px;
  width: 100%;
  display: flex;
  position: relative;
}

.footer .title {
  color: var(--themeiron);
  font-family: var(--theme-inter-regular-11-63-font-family);
  font-size: var(--theme-inter-regular-11-63-font-size);
  font-style: var(--theme-inter-regular-11-63-font-style);
  font-weight: var(--theme-inter-regular-11-63-font-weight);
  letter-spacing: var(--theme-inter-regular-11-63-letter-spacing);
  line-height: var(--theme-inter-regular-11-63-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.footer .list {
  flex-wrap: wrap;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 0;
  width: 100%;
  display: flex;
  position: relative;
}

.footer .item-2 {
  flex-direction: column;
  align-items: center;
  width: 130px;
  max-width: 130px;
  display: flex;
  position: relative;
}

.footer .logo-wrapper {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.footer .logo {
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  width: 100%;
  height: 70px;
  display: flex;
  position: relative;
}

.footer .logo-2 {
  background-image: url("logo-3.492ead9c.png");
  background-position: 50%;
  background-size: cover;
  flex: 1;
  align-self: stretch;
  width: 100%;
  max-width: 90px;
  position: relative;
}

.footer .logo-3 {
  background-image: url("logo-4.ea5ff23a.png");
  background-position: 50%;
  background-size: cover;
  flex: 1;
  align-self: stretch;
  width: 100%;
  max-width: 90px;
  position: relative;
}

.footer .logo-4 {
  background-image: url("logo-5.5932d256.png");
  background-position: 50%;
  background-size: cover;
  flex: 1;
  align-self: stretch;
  width: 100%;
  max-width: 90px;
  position: relative;
}

.footer .bundesliga-footer {
  flex-wrap: wrap;
  flex: none;
  align-items: center;
  gap: 0;
  display: inline-flex;
  position: relative;
}

.footer .link-4 {
  flex: none;
  display: block;
  position: relative;
}

.footer .link-5 {
  flex: none;
  position: relative;
}

.page-header {
  flex-direction: column;
  align-items: center;
  gap: 32px;
  max-width: 1200px;
  display: flex;
  position: relative;
}

.page-header .header-wrapper {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 8px;
  width: 100%;
  max-width: 1200px;
  padding: 0 10px;
  display: flex;
  position: relative;
}

.page-header .title-2 {
  color: var(--themeswamp);
  font-family: var(--theme-inter-black-36-25-font-family);
  font-size: var(--theme-inter-black-36-25-font-size);
  font-style: var(--theme-inter-black-36-25-font-style);
  font-weight: var(--theme-inter-black-36-25-font-weight);
  letter-spacing: var(--theme-inter-black-36-25-letter-spacing);
  line-height: var(--theme-inter-black-36-25-line-height);
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.page-header .subtitle-2 {
  color: var(--themeswamp);
  font-family: var(--theme-inter-bold-15-75-upper-font-family);
  font-size: var(--theme-inter-bold-15-75-upper-font-size);
  font-style: var(--theme-inter-bold-15-75-upper-font-style);
  font-weight: var(--theme-inter-bold-15-75-upper-font-weight);
  letter-spacing: var(--theme-inter-bold-15-75-upper-letter-spacing);
  line-height: var(--theme-inter-bold-15-75-upper-line-height);
  align-self: stretch;
  position: relative;
}

.view-default-wrapper {
  background-color: #f5f5f5;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: flex;
  position: relative;
}

.view-default-wrapper .main-navigation-2 {
  background-color: var(--themewhite);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.view-default-wrapper .league-rows-2 {
  border-color: #e9ebed;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  height: 36px;
  padding: 0 0 10px;
  display: flex;
  position: relative;
}

.view-default-wrapper .leauge-row-wrapper-2 {
  flex: 1;
  align-self: stretch;
  align-items: flex-start;
  gap: 15px;
  width: 100%;
  max-width: 1440px;
  padding: 5.08px 18px;
  display: flex;
  position: relative;
}

.view-default-wrapper .league-row-item-6 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  margin-bottom: -8.16px;
  display: inline-flex;
  position: relative;
}

.view-default-wrapper .league-row-item-wrapper {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 24px;
  display: inline-flex;
  position: relative;
}

.view-default-wrapper .league-row-item-7 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 3px;
  width: 50px;
  display: inline-flex;
  position: relative;
}

.view-default-wrapper .content-2 {
  flex: none;
  justify-content: space-between;
  align-items: flex-start;
  gap: 5px;
  display: flex;
  position: relative;
}

.view-default-wrapper .league-row-item-8 {
  width: 16px;
  height: 13px;
  position: relative;
}

.view-default-wrapper .league-row-item-9 {
  font-family: var(--theme-inter-bold-12-font-family);
  font-size: var(--theme-inter-bold-12-font-size);
  font-style: var(--theme-inter-bold-12-font-style);
  font-weight: var(--theme-inter-bold-12-font-weight);
  letter-spacing: var(--theme-inter-bold-12-letter-spacing);
  line-height: var(--theme-inter-bold-12-line-height);
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.view-default-wrapper .league-row-item-10 {
  background-color: #000;
  align-self: stretch;
  width: 100%;
  height: 1px;
  position: relative;
}

.view-default-wrapper .league-row-item-11 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  margin-bottom: -8.16px;
  display: inline-flex;
  position: relative;
}

.view-default-wrapper .league-row-item-12 {
  width: 16px;
  height: 13px;
  position: relative;
}

.view-default-wrapper .league-row-item-13 {
  color: var(--themeouter-space);
  font-family: var(--theme-inter-bold-12-font-family);
  font-size: var(--theme-inter-bold-12-font-size);
  font-style: var(--theme-inter-bold-12-font-style);
  font-weight: var(--theme-inter-bold-12-font-weight);
  letter-spacing: var(--theme-inter-bold-12-letter-spacing);
  line-height: var(--theme-inter-bold-12-line-height);
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.view-default-wrapper .league-row-item-14 {
  align-self: stretch;
  width: 100%;
  height: 1px;
  position: relative;
}

.view-default-wrapper .league-row-item-15 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  margin-bottom: -8.16px;
  display: inline-flex;
  position: relative;
}

.view-default-wrapper .league-row-item-16 {
  width: 16px;
  height: 13px;
  position: relative;
}

.view-default-wrapper .league-row-item-17 {
  color: var(--themeouter-space);
  font-family: var(--theme-inter-bold-12-font-family);
  font-size: var(--theme-inter-bold-12-font-size);
  font-style: var(--theme-inter-bold-12-font-style);
  font-weight: var(--theme-inter-bold-12-font-weight);
  letter-spacing: var(--theme-inter-bold-12-letter-spacing);
  line-height: var(--theme-inter-bold-12-line-height);
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.view-default-wrapper .league-row-item-18 {
  align-self: stretch;
  width: 100%;
  height: 1px;
  position: relative;
}

.view-default-wrapper .league-row-item-19 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  margin-bottom: -8.16px;
  display: inline-flex;
  position: relative;
}

.view-default-wrapper .league-row-item-20 {
  width: 16px;
  height: 13px;
  position: relative;
}

.view-default-wrapper .league-row-item-21 {
  color: var(--themeouter-space);
  font-family: var(--theme-inter-bold-12-font-family);
  font-size: var(--theme-inter-bold-12-font-size);
  font-style: var(--theme-inter-bold-12-font-style);
  font-weight: var(--theme-inter-bold-12-font-weight);
  letter-spacing: var(--theme-inter-bold-12-letter-spacing);
  line-height: var(--theme-inter-bold-12-line-height);
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.view-default-wrapper .league-row-item-22 {
  align-self: stretch;
  width: 100%;
  height: 1px;
  position: relative;
}

.view-default-wrapper .league-row-item-23 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  margin-bottom: -8.16px;
  display: inline-flex;
  position: relative;
}

.view-default-wrapper .league-row-item-24 {
  width: 16px;
  height: 13px;
  position: relative;
}

.view-default-wrapper .league-row-item-25 {
  color: var(--themeouter-space);
  font-family: var(--theme-inter-bold-12-font-family);
  font-size: var(--theme-inter-bold-12-font-size);
  font-style: var(--theme-inter-bold-12-font-style);
  font-weight: var(--theme-inter-bold-12-font-weight);
  letter-spacing: var(--theme-inter-bold-12-letter-spacing);
  line-height: var(--theme-inter-bold-12-line-height);
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.view-default-wrapper .league-row-item-26 {
  align-self: stretch;
  width: 100%;
  height: 1px;
  position: relative;
}

.view-default-wrapper .league-row-item-27 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  margin-bottom: -8.16px;
  display: inline-flex;
  position: relative;
}

.view-default-wrapper .league-row-item-28 {
  width: 16px;
  height: 13px;
  position: relative;
}

.view-default-wrapper .league-row-item-29 {
  color: var(--themeouter-space);
  font-family: var(--theme-inter-bold-12-font-family);
  font-size: var(--theme-inter-bold-12-font-size);
  font-style: var(--theme-inter-bold-12-font-style);
  font-weight: var(--theme-inter-bold-12-font-weight);
  letter-spacing: var(--theme-inter-bold-12-letter-spacing);
  line-height: var(--theme-inter-bold-12-line-height);
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.view-default-wrapper .league-row-item-30 {
  align-self: stretch;
  width: 100%;
  height: 1px;
  position: relative;
}

.view-default-wrapper .league-row-item-31 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  margin-bottom: -8.16px;
  display: inline-flex;
  position: relative;
}

.view-default-wrapper .league-row-item-32 {
  width: 16px;
  height: 13px;
  position: relative;
}

.view-default-wrapper .league-row-item-33 {
  color: var(--themeouter-space);
  font-family: var(--theme-inter-bold-12-font-family);
  font-size: var(--theme-inter-bold-12-font-size);
  font-style: var(--theme-inter-bold-12-font-style);
  font-weight: var(--theme-inter-bold-12-font-weight);
  letter-spacing: var(--theme-inter-bold-12-letter-spacing);
  line-height: var(--theme-inter-bold-12-line-height);
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.view-default-wrapper .league-row-item-34 {
  align-self: stretch;
  width: 100%;
  height: 1px;
  position: relative;
}

.view-default-wrapper .league-row-item-35 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  margin-bottom: -8.16px;
  display: inline-flex;
  position: relative;
}

.view-default-wrapper .league-row-item-36 {
  width: 16px;
  height: 13px;
  position: relative;
}

.view-default-wrapper .league-row-item-37 {
  color: var(--themeouter-space);
  font-family: var(--theme-inter-bold-12-font-family);
  font-size: var(--theme-inter-bold-12-font-size);
  font-style: var(--theme-inter-bold-12-font-style);
  font-weight: var(--theme-inter-bold-12-font-weight);
  letter-spacing: var(--theme-inter-bold-12-letter-spacing);
  line-height: var(--theme-inter-bold-12-line-height);
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.view-default-wrapper .league-row-item-38 {
  align-self: stretch;
  width: 100%;
  height: 1px;
  position: relative;
}

.view-default-wrapper .league-row-item-39 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  margin-bottom: -8.16px;
  display: inline-flex;
  position: relative;
}

.view-default-wrapper .league-row-item-40 {
  width: 16px;
  height: 13px;
  position: relative;
}

.view-default-wrapper .league-row-item-41 {
  color: var(--themeouter-space);
  font-family: var(--theme-inter-bold-12-font-family);
  font-size: var(--theme-inter-bold-12-font-size);
  font-style: var(--theme-inter-bold-12-font-style);
  font-weight: var(--theme-inter-bold-12-font-weight);
  letter-spacing: var(--theme-inter-bold-12-letter-spacing);
  line-height: var(--theme-inter-bold-12-line-height);
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.view-default-wrapper .league-row-item-42 {
  align-self: stretch;
  width: 100%;
  height: 1px;
  position: relative;
}

.view-default-wrapper .nav-row-wrapper-4 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  padding: 15px 18px 10px;
  display: flex;
  position: relative;
}

.view-default-wrapper .nav-row-wrapper-5 {
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  height: 30.92px;
  display: flex;
  position: relative;
}

.view-default-wrapper .nav-row-wrapper-6 {
  object-fit: cover;
  width: 120px;
  height: 44.03px;
  margin-top: -6.56px;
  margin-bottom: -6.56px;
  position: relative;
}

.view-default-wrapper .nav-row-wrapper-7 {
  flex: none;
  align-self: stretch;
  align-items: center;
  display: inline-flex;
  position: relative;
}

.view-default-wrapper .nav-row-wrapper-8 {
  flex-wrap: wrap;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 0;
  height: 56px;
  margin-top: -12.54px;
  margin-bottom: -12.54px;
  display: inline-flex;
  position: relative;
}

.view-default-wrapper .item-3 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  padding: 0 0 0 8px;
  display: inline-flex;
  position: relative;
}

.view-default-wrapper .link-6 {
  flex: 1;
  justify-content: flex-end;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 16.15px 16px 16.99px 0;
  display: flex;
  position: relative;
}

.view-default-wrapper .text-wrapper-8 {
  color: var(--themeoslo-gray);
  font-family: var(--theme-inter-regular-13-25-font-family);
  font-size: var(--theme-inter-regular-13-25-font-size);
  font-style: var(--theme-inter-regular-13-25-font-style);
  font-weight: var(--theme-inter-regular-13-25-font-weight);
  letter-spacing: var(--theme-inter-regular-13-25-letter-spacing);
  line-height: var(--theme-inter-regular-13-25-line-height);
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1.07px;
  position: relative;
}

.view-default-wrapper .text-wrapper-9 {
  color: var(--themeoslo-gray);
  font-family: var(--theme-inter-regular-12-75-font-family);
  font-size: var(--theme-inter-regular-12-75-font-size);
  font-style: var(--theme-inter-regular-12-75-font-style);
  font-weight: var(--theme-inter-regular-12-75-font-weight);
  letter-spacing: var(--theme-inter-regular-12-75-letter-spacing);
  line-height: var(--theme-inter-regular-12-75-line-height);
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1.07px;
  position: relative;
}

.view-default-wrapper .link-7 {
  flex: 1;
  align-self: stretch;
  width: 100%;
  position: relative;
}

.view-default-wrapper .nav-row-wrapper-9 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  height: 56px;
  margin-top: -12.54px;
  margin-bottom: -12.54px;
  display: inline-flex;
  position: relative;
}

.view-default-wrapper .nav-row-wrapper-10 {
  opacity: .5;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  display: inline-flex;
  position: relative;
}

.view-default-wrapper .nav-row-wrapper-11 {
  align-self: stretch;
  width: 24px;
  position: relative;
}

.view-default-wrapper .text-wrapper-10 {
  color: var(--themeoslo-gray);
  font-family: var(--theme-inter-regular-13-13-font-family);
  font-size: var(--theme-inter-regular-13-13-font-size);
  font-style: var(--theme-inter-regular-13-13-font-style);
  font-weight: var(--theme-inter-regular-13-13-font-weight);
  letter-spacing: var(--theme-inter-regular-13-13-letter-spacing);
  line-height: var(--theme-inter-regular-13-13-line-height);
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.view-default-wrapper .mobile-burger-menu-2 {
  width: 24px;
  display: block;
  position: relative;
}

.view-default-wrapper .nav-row-wrapper-12 {
  flex: none;
  align-self: stretch;
  align-items: center;
  margin-left: -280px;
  margin-right: -176px;
  display: inline-flex;
  position: relative;
}

.view-default-wrapper .mobile-burger-menu-3 {
  width: 24px;
  margin-left: -200px;
  display: block;
  position: relative;
}

.view-default-wrapper.default {
  background-color: #fff;
  width: 100%;
}

.view-default-wrapper.mobile {
  width: 390px;
}

.view-default-wrapper.mobile .league-row-item-27 {
  margin-right: -15px;
}

.view-default-wrapper.mobile .league-row-item-31 {
  margin-right: -79px;
}

.view-default-wrapper.mobile .league-row-item-32 {
  margin-bottom: -3926.42px;
  margin-right: -3987px;
}

.view-default-wrapper.mobile .league-row-item-33 {
  margin-left: -4016px;
}

.view-default-wrapper.mobile .league-row-item-34 {
  margin-bottom: -3928.42px;
  margin-right: -4020px;
}

.view-default-wrapper.mobile .league-row-item-35 {
  margin-right: -143px;
}

.view-default-wrapper.mobile .league-row-item-36 {
  margin-bottom: -3926.42px;
  margin-right: -3923px;
}

.view-default-wrapper.mobile .league-row-item-37 {
  margin-left: -3953px;
}

.view-default-wrapper.mobile .league-row-item-38 {
  margin-bottom: -3928.42px;
  margin-right: -3956px;
}

.view-default-wrapper.mobile .league-row-item-39 {
  margin-right: -207px;
}

.view-default-wrapper.mobile .league-row-item-40 {
  margin-bottom: -3926.42px;
  margin-right: -3859px;
}

.view-default-wrapper.mobile .league-row-item-41 {
  margin-left: -3888px;
}

.view-default-wrapper.mobile .league-row-item-42 {
  margin-bottom: -3928.42px;
  margin-right: -3892px;
}

.league-row-item-6.active {
  color: #fff;
  background-color: #000;
}

.navigation-desktop {
  background-color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  display: flex;
  position: relative;
}

.navigation-desktop .main-navigation {
  background-color: var(--themewhite);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.navigation-desktop .league-rows {
  border-color: #e9ebed;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  height: 36px;
  padding: 0 0 10px;
  display: flex;
  position: relative;
  overflow-x: scroll;
}

.navigation-desktop .leauge-row-wrapper {
  flex: 1;
  align-self: stretch;
  align-items: flex-start;
  gap: 15px;
  width: 100%;
  max-width: 1440px;
  padding: 5.08px 18px;
  display: flex;
  position: relative;
}

.navigation-desktop .league-row-item {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  margin-bottom: -8.16px;
  display: inline-flex;
  position: relative;
}

.navigation-desktop .link {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 24px;
  display: inline-flex;
  position: relative;
}

.league-row-item.active .link {
  color: #fff;
  background-color: #000;
  padding-left: 2px;
  padding-right: 2px;
}

.navigation-desktop .div {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 3px;
  display: inline-flex;
  position: relative;
}

.navigation-desktop .content {
  flex: none;
  justify-content: space-between;
  align-items: flex-start;
  width: 49px;
  display: flex;
  position: relative;
}

.navigation-desktop .img {
  width: 16px;
  height: 13px;
  position: relative;
}

.navigation-desktop .text-wrapper {
  font-family: var(--theme-inter-bold-12-font-family);
  font-size: var(--theme-inter-bold-12-font-size);
  font-style: var(--theme-inter-bold-12-font-style);
  font-weight: var(--theme-inter-bold-12-font-weight);
  letter-spacing: var(--theme-inter-bold-12-letter-spacing);
  line-height: var(--theme-inter-bold-12-line-height);
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.navigation-desktop .league-row-item-2 {
  background-color: #000;
  align-self: stretch;
  width: 100%;
  height: 1.5px;
  position: relative;
}

.navigation-desktop .link-wrapper {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  margin-bottom: -8.16px;
  margin-right: -15px;
  display: inline-flex;
  position: relative;
}

.navigation-desktop .league-row-item-3 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  margin-bottom: -8.16px;
  margin-right: -79px;
  display: inline-flex;
  position: relative;
}

.navigation-desktop .league-row-item-4 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  margin-bottom: -8.16px;
  margin-right: -143px;
  display: inline-flex;
  position: relative;
}

.navigation-desktop .league-row-item-5 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  margin-bottom: -8.16px;
  margin-right: -207px;
  display: inline-flex;
  position: relative;
}

.navigation-desktop .nav-row-wrapper {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  padding: 15px 18px 10px;
  display: flex;
  position: relative;
}

.navigation-desktop .nav-row-wrapper-2 {
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  height: 30.92px;
  display: flex;
  position: relative;
}

.navigation-desktop .nav-row-wrapper-3 {
  object-fit: cover;
  width: 120px;
  height: 44.03px;
  margin-top: -6.56px;
  margin-bottom: -6.56px;
  position: relative;
}

.navigation-desktop .mobile-burger-menu {
  width: 24px;
  position: relative;
}

.drawer-overlay {
  z-index: 999;
  background-color: #00000080;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.drawer-content {
  background-color: #fff;
  flex-direction: column;
  justify-content: space-between;
  width: 300px;
  height: 100%;
  display: flex;
  position: relative;
  box-shadow: -2px 0 10px #0000001a;
}

.close-button {
  cursor: pointer;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 20px;
  right: 20px;
}

.league-row-wrapper {
  gap: 15px;
  padding-left: 12px;
  display: flex;
}

.element-mobilenav {
  flex-direction: column;
  align-items: flex-start;
  width: 390px;
  height: 100%;
  display: flex;
  position: relative;
}

.element-mobilenav .div-13 {
  flex-direction: column;
  align-items: flex-start;
  width: 390px;
  height: 100%;
  display: flex;
}

.element-mobilenav .nav-row-wrapper-16 {
  background-color: #000;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  padding: 15px 18px 10px;
  display: flex;
  position: relative;
}

.element-mobilenav .nav-row-wrapper-17 {
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  height: 30.92px;
  display: flex;
  position: relative;
}

.element-mobilenav .filler {
  width: 20px;
  height: 14px;
  position: relative;
}

.element-mobilenav .close {
  width: 24px;
  position: relative;
}

.element-mobilenav .mobile-menu-items {
  background-color: #fff;
  flex-direction: column;
  flex: 1;
  align-self: stretch;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding: 19px 32px;
  display: flex;
  position: relative;
  box-shadow: 0 4px 20px #000e140d;
}

.element-mobilenav .mobile-menu-item {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  display: flex;
  position: relative;
}

.element-mobilenav .mobile-menu-item-2 {
  color: #888c90;
  letter-spacing: 0;
  white-space: nowrap;
  flex: 1;
  height: 27px;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 16.6px;
  font-weight: 400;
  line-height: 30px;
  position: relative;
}

.element-mobilenav .mobile-menu-item-wrapper {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  margin-bottom: -5.92px;
  display: flex;
  position: relative;
}

.element-mobilenav .mobile-menu-login {
  background-color: #000;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 20px 32px;
  display: flex;
  position: relative;
}

.element-mobilenav .text-wrapper-24 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 13.2px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
}

.element-mobilenav .dfl-simple-icon-user {
  width: 24px;
  height: 24px;
  position: relative;
}

.element-clubs-desktop {
  background-color: #f5f5f5;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.element-clubs-desktop .class {
  margin-bottom: -906.42px !important;
  margin-right: -780px !important;
}

.element-clubs-desktop .class-2 {
  margin-bottom: -908.42px !important;
  margin-right: -749px !important;
}

.element-clubs-desktop .class-3 {
  margin-bottom: -908.42px !important;
  margin-right: -813px !important;
}

.element-clubs-desktop .class-4 {
  margin-left: -746px !important;
}

.element-clubs-desktop .class-5 {
  margin-bottom: -906.42px !important;
  margin-right: -652px !important;
}

.element-clubs-desktop .class-6 {
  margin-left: -681px !important;
}

.element-clubs-desktop .class-7 {
  margin-left: -809px !important;
}

.element-clubs-desktop .class-8 {
  margin-bottom: -908.42px !important;
  margin-right: -685px !important;
}

.element-clubs-desktop .class-9 {
  margin-bottom: -906.42px !important;
  margin-right: -716px !important;
}

.element-clubs-desktop .instance-node-4 {
  padding-top: 40px;
  flex: none !important;
  width: 100% !important;
}

.element-clubs-desktop .page-content-3 {
  flex: none;
  align-self: stretch;
  width: 100%;
  display: flex;
  position: relative;
}

.element-clubs-desktop .club-grid {
  flex-wrap: wrap;
  flex: none;
  justify-content: flex-start;
  align-items: center;
  gap: 15px 32px;
  width: 100%;
  max-width: 1200px;
  padding-top: 20px;
  padding-bottom: 60px;
  display: flex;
  position: relative;
}

.element-clubs-desktop .club-card-instance {
  min-width: 289px !important;
  width: unset !important;
  flex: 1 !important;
}

.element-clubs-desktop .page-content-4 {
  flex-direction: column;
  flex: 1;
  align-items: center;
  gap: 20px;
  max-width: 1200px;
  padding: 0 20px;
  display: flex;
  position: relative;
}

.element-clubs-desktop .class-10 {
  margin-left: -324px !important;
}

.element-clubs-desktop .footer-6 {
  background-color: #0000 !important;
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
}

.sponsors .sportsselection {
  object-fit: cover;
  height: 50px;
  position: relative;
}

.sponsor {
  object-fit: contain;
  width: 100px;
  height: 50px;
  position: relative;
}

.sponsorc {
  object-fit: cover;
  width: 100px;
  height: 50px;
  position: relative;
}

.element-contact-mobile {
  background-color: #f5f5f5;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  display: flex;
  position: relative;
}

.element-contact-mobile .instance-node-13 {
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
}

.element-contact-mobile .page-content-19 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 20px;
  width: 100%;
  max-width: 1200px;
  padding: 0 16px;
  display: flex;
  position: relative;
}

.element-contact-mobile .contact {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 32px;
  width: 100%;
  max-width: 1200px;
  padding: 0 32px 60px;
  display: flex;
  position: relative;
}

.element-contact-mobile .contact-header {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
  display: flex;
  position: relative;
}

.element-contact-mobile .title-9 {
  color: var(--themeswamp);
  font-family: var(--theme-inter-black-37-5-font-family);
  font-size: var(--theme-inter-black-37-5-font-size);
  font-style: var(--theme-inter-black-37-5-font-style);
  font-weight: var(--theme-inter-black-37-5-font-weight);
  letter-spacing: var(--theme-inter-black-37-5-letter-spacing);
  line-height: var(--theme-inter-black-37-5-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.element-contact-mobile .div-wrapper-5 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.element-contact-mobile .sterreichischer {
  color: var(--themeswamp);
  font-family: var(--theme-inter-bold-15-75-upper-font-family);
  font-size: var(--theme-inter-bold-15-75-upper-font-size);
  font-style: var(--theme-inter-bold-15-75-upper-font-style);
  font-weight: var(--theme-inter-bold-15-75-upper-font-weight);
  letter-spacing: var(--theme-inter-bold-15-75-upper-letter-spacing);
  line-height: var(--theme-inter-bold-15-75-upper-line-height);
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.element-contact-mobile .contact-content-text {
  color: var(--themeouter-space);
  font-family: var(--theme-inter-regular-15-25-font-family);
  font-size: var(--theme-inter-regular-15-25-font-size);
  font-style: var(--theme-inter-regular-15-25-font-style);
  font-weight: var(--theme-inter-regular-15-25-font-weight);
  letter-spacing: var(--theme-inter-regular-15-25-letter-spacing);
  line-height: var(--theme-inter-regular-15-25-line-height);
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.element-contact-mobile .footer-28 {
  background-color: #0000 !important;
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
}

.element-contact-mobile .footer-29 {
  margin-left: -324px !important;
}

.element-contact-mobile .page-frame-3 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  display: flex;
  position: relative;
}

.element-contact-mobile .page-content-20 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 20px;
  width: 900px;
  max-width: 1200px;
  padding: 0 16px;
  display: flex;
  position: relative;
}

.element-contact-mobile .contact-2 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 32px;
  width: 868px;
  max-width: 1200px;
  padding: 0 32px 60px;
  display: flex;
  position: relative;
}

.div-wrapper {
  align-items: flex-start;
  width: 436px;
  padding: 4px 0;
  display: flex;
  position: relative;
}

.div-wrapper .team-detail-squad-4 {
  flex-wrap: wrap;
  flex: 1;
  align-items: flex-start;
  gap: 0;
  display: flex;
  position: relative;
}

.div-wrapper .team-detail-squad-5 {
  background-color: var(--themewhite);
  flex-wrap: wrap;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  gap: 0;
  padding: 8px 16.01px 8px 16px;
  display: flex;
  position: relative;
  box-shadow: 0 2px 8px #000e140d;
}

.div-wrapper .frame-3 {
  flex: none;
  align-items: flex-start;
  gap: 5px;
  display: inline-flex;
  position: relative;
}

.div-wrapper .team-detail-squad-6 {
  background-position: 50%;
  background-size: cover;
  width: 48px;
  height: 48px;
  position: relative;
}

.div-wrapper .team-detail-squad-7 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.div-wrapper .team-detail-squad-8 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 4px;
  display: inline-flex;
  position: relative;
}

.div-wrapper .team-detail-squad-9 {
  color: var(--themeouter-space);
  font-family: var(--theme-inter-regular-15-63-font-family);
  font-size: var(--theme-inter-regular-15-63-font-size);
  font-style: var(--theme-inter-regular-15-63-font-style);
  font-weight: var(--theme-inter-regular-15-63-font-weight);
  letter-spacing: var(--theme-inter-regular-15-63-letter-spacing);
  line-height: var(--theme-inter-regular-15-63-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.div-wrapper .team-detail-squad-10 {
  color: var(--themeouter-space);
  font-family: var(--theme-inter-bold-15-63-font-family);
  font-size: var(--theme-inter-bold-15-63-font-size);
  font-style: var(--theme-inter-bold-15-63-font-style);
  font-weight: var(--theme-inter-bold-15-63-font-weight);
  letter-spacing: var(--theme-inter-bold-15-63-letter-spacing);
  line-height: var(--theme-inter-bold-15-63-line-height);
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.div-wrapper .frame-4 {
  flex: none;
  align-items: flex-end;
  gap: 10px;
  display: inline-flex;
  position: relative;
}

.div-wrapper .nationality-flags-wrapper {
  flex-direction: column;
  align-items: flex-start;
  width: 41px;
  padding: 2.55px 0 4px;
  display: flex;
  position: relative;
}

.div-wrapper .nationality-flags-3 {
  flex: none;
  align-items: center;
  margin-right: -.32px;
  display: inline-flex;
  position: relative;
}

.div-wrapper .flagicon-3 {
  border: 1px solid;
  border-color: var(--themegallery);
  background-position: 50%;
  background-size: cover;
  width: 20.66px;
  height: 16px;
  position: relative;
}

.div-wrapper .flagicon-4 {
  border: 1px solid;
  border-color: var(--themegallery);
  width: 20.66px;
  height: 16px;
  margin-left: --32e-15px;
  background-position: 50%;
  background-size: cover;
  position: relative;
}

.div-wrapper .team-detail-squad-11 {
  color: var(--themeoslo-gray);
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
}

.image-wrapper {
  border-radius: 4px;
  width: 100%;
  height: 100%;
}

.event-row {
  border-color: #0003;
  border-bottom-style: solid;
  border-bottom-width: .3px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 716px;
  padding: 1px 1px 8px;
  display: flex;
  position: relative;
}

.event-row .event-card-bottom {
  background-color: #fff;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 15px;
  width: 100%;
  display: flex;
  position: relative;
}

.event-row .playerdata-home, .event-row .playerdata-away {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.event-row .event-card-container {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-end;
  width: 100%;
  min-height: 48px;
  padding: 12px 0;
  display: flex;
  position: relative;
}

.event-row .player-data-wrapper {
  flex-wrap: wrap;
  flex: none;
  justify-content: flex-end;
  align-self: stretch;
  align-items: center;
  gap: 0;
  width: 100%;
  display: flex;
  position: relative;
}

.event-row .player-data {
  flex-direction: column;
  flex: 1;
  align-items: flex-end;
  display: flex;
  position: relative;
}

.event-row .event-card-data {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 50px;
  padding: 0 10px 0 0;
  display: inline-flex;
  position: relative;
}

.event-row .event-card-data-2 {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.event-row .event-card-player {
  background-position: 50%;
  background-size: cover;
  width: 50px;
  height: 50px;
  position: relative;
}

.event-row .event-card-team {
  flex-direction: column;
  align-items: flex-start;
  width: 24px;
  display: flex;
  position: absolute;
  top: 32px;
  left: 32px;
}

.event-row .event-card-team-img {
  background-position: 50%;
  background-size: cover;
  align-self: stretch;
  width: 100%;
  height: 23.95px;
  position: relative;
}

.event-row .event-card-name {
  flex-wrap: wrap;
  flex: none;
  justify-content: flex-end;
  align-self: stretch;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.event-row .event-card-first {
  color: var(--themeouter-space);
  font-family: var(--theme-inter-regular-15-75-font-family);
  font-size: var(--theme-inter-regular-15-75-font-size);
  font-style: var(--theme-inter-regular-15-75-font-style);
  font-weight: var(--theme-inter-regular-15-75-font-weight);
  letter-spacing: var(--theme-inter-regular-15-75-letter-spacing);
  line-height: var(--theme-inter-regular-15-75-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  padding-right: 3px;
  position: relative;
}

.event-row .event-card-last-name {
  color: var(--themeouter-space);
  font-family: var(--theme-inter-bold-15-75-font-family);
  font-size: var(--theme-inter-bold-15-75-font-size);
  font-style: var(--theme-inter-bold-15-75-font-style);
  font-weight: var(--theme-inter-bold-15-75-font-weight);
  letter-spacing: var(--theme-inter-bold-15-75-letter-spacing);
  line-height: var(--theme-inter-bold-15-75-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.event-row .event-card-score {
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 6px;
  width: 56px;
  display: flex;
  position: relative;
}

.event-row .event-card-minute {
  flex-direction: column;
  flex: none;
  align-items: center;
  width: 56px;
  display: flex;
  position: relative;
}

.event-row .event-card-minute-2 {
  color: var(--themeouter-space);
  font-family: var(--theme-inter-regular-14-font-family);
  font-size: var(--theme-inter-regular-14-font-size);
  font-style: var(--theme-inter-regular-14-font-style);
  font-weight: var(--theme-inter-regular-14-font-weight);
  letter-spacing: var(--theme-inter-regular-14-letter-spacing);
  line-height: var(--theme-inter-regular-14-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.event-row .event-card-type-img {
  flex: none;
  min-width: 56px;
  position: relative;
}

.event-row .event-card-score-2 {
  color: var(--themeouter-space);
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 17px;
  font-weight: 500;
  line-height: 24px;
  position: relative;
}

.event-row .event-card-bottom-wrapper {
  background-color: var(--themewhite);
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  min-height: 48px;
  padding: 12px 0;
  display: flex;
  position: relative;
}

.event-row .event-card-bottom-2 {
  flex-wrap: wrap;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 0;
  width: 100%;
  display: flex;
  position: relative;
}

.event-row .player-data-2 {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.event-row .event-card-name-2 {
  flex-wrap: wrap;
  flex: none;
  align-self: stretch;
  width: 100%;
  display: flex;
  position: relative;
}

.event-row .goal-home {
  justify-content: center;
}

.event-row .property-1-0-yellowred-away {
  align-items: flex-start;
  gap: 4px;
}

.event-row .property-1-0-goal-away {
  align-items: center;
  gap: 0;
  padding: 10px 0;
}

.event-row .property-1-0-yellow-home, .event-row .property-1-0-goal-home, .event-row .property-1-0-red-away, .event-row .property-1-0-red-home, .event-row .property-1-0-yellowred-home, .event-row .property-1-0-yellow-away {
  align-items: flex-start;
  gap: 4px;
}

.event-row .player-data.yellowred-away .event-card-name, .event-row .player-data.goal-away .event-card-name {
  gap: 0;
}

.event-row .player-data.yellow-home .event-card-name, .event-row .player-data.goal-home .event-card-name {
  gap: 0;
  padding: 10px 0;
}

.event-row .player-data.red-away .event-card-name {
  gap: 0;
}

.event-row .player-data.red-home .event-card-name, .event-row .player-data.yellowred-home .event-card-name {
  gap: 4px;
}

.event-row .player-data.yellow-away .event-card-name {
  gap: 0;
}

.team-detail-squad-wrapper {
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding: 0 0 10px;
  display: flex;
  position: relative;
}

.team-detail-squad-wrapper .team-detail-team {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.team-detail-squad-wrapper .team-detail-team-wrapper {
  flex-wrap: wrap;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 0;
  width: 100%;
  display: flex;
  position: relative;
}

.team-detail-squad-wrapper .team-detail-team-2 {
  color: #fff;
  background-color: #000;
  flex-wrap: wrap;
  flex: 1;
  align-items: center;
  gap: 5px;
  margin-right: 5px;
  padding: 8px 10px;
  display: flex;
  position: relative;
  box-shadow: 0 2px 8px #000e140d;
}

.team-detail-squad-wrapper .club-img {
  background-color: #fff;
  background-position: 50%;
  background-size: cover;
  width: 50px;
  max-width: 80px;
  height: 50px;
  margin-right: 10px;
  padding: 3px;
  position: relative;
}

.team-detail-squad-wrapper .team-detail-team-3 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  padding: 0;
  display: inline-flex;
  position: relative;
}

.team-detail-squad-wrapper .team-detail-team-4 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 4px;
  width: 100%;
  display: flex;
  position: relative;
}

.team-detail-squad-wrapper .team-detail-squad-2 {
  font-family: var(--theme-inter-bold-15-63-font-family);
  font-size: var(--theme-inter-bold-15-63-font-size);
  font-style: var(--theme-inter-bold-15-63-font-style);
  font-weight: var(--theme-inter-bold-15-63-font-weight);
  letter-spacing: var(--theme-inter-bold-15-63-letter-spacing);
  line-height: var(--theme-inter-bold-15-63-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.team-detail-squad-wrapper .team-detail-squad-3 {
  font-family: var(--theme-inter-regular-15-63-font-family);
  font-size: var(--theme-inter-regular-15-63-font-size);
  font-style: var(--theme-inter-regular-15-63-font-style);
  font-weight: var(--theme-inter-regular-15-63-font-weight);
  letter-spacing: var(--theme-inter-regular-15-63-letter-spacing);
  line-height: var(--theme-inter-regular-15-63-line-height);
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.element-game-report {
  background-color: #f5f5f5;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  display: flex;
  position: relative;
}

.element-game-report .design-component-instance-node-2 {
  flex: none !important;
  width: 100% !important;
}

.element-game-report .game-report-wrapper {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  width: 100%;
  max-width: 1140px;
  display: flex;
  position: relative;
}

.element-game-report .game-report-wrapper-2 {
  background-color: var(--themewhite);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.element-game-report .game-report-top-row {
  flex-wrap: wrap;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 0;
  width: 100%;
  height: 46px;
  padding: 0 32px;
  display: flex;
  position: relative;
}

.element-game-report .left-wrapper {
  flex: 1;
  align-items: center;
  max-width: 285px;
  height: 46px;
  padding: 0 0 0 8px;
  display: flex;
  position: relative;
}

.element-game-report .img-3 {
  flex: none;
  position: relative;
}

.element-game-report .game-report-back {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  padding: 0 0 0 8px;
  display: inline-flex;
  position: relative;
}

.element-game-report .game-report-back-2 {
  color: var(--themeouter-space);
  font-family: var(--theme-inter-regular-13-45-font-family);
  font-size: var(--theme-inter-regular-13-45-font-size);
  font-style: var(--theme-inter-regular-13-45-font-style);
  font-weight: var(--theme-inter-regular-13-45-font-weight);
  letter-spacing: var(--theme-inter-regular-13-45-letter-spacing);
  line-height: var(--theme-inter-regular-13-45-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.element-game-report .middle-wrapper {
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  max-width: 570px;
  height: 46px;
  padding: 0 265.4px 0 265.41px;
  display: flex;
  position: relative;
}

.element-game-report .middle-wrapper-game {
  color: var(--themeouter-space);
  font-family: var(--theme-inter-regular-13-78-font-family);
  font-size: var(--theme-inter-regular-13-78-font-size);
  font-style: var(--theme-inter-regular-13-78-font-style);
  font-weight: var(--theme-inter-regular-13-78-font-weight);
  letter-spacing: var(--theme-inter-regular-13-78-letter-spacing);
  line-height: var(--theme-inter-regular-13-78-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  margin-left: -231.07px;
  margin-right: -231.07px;
  position: relative;
}

.element-game-report .right-wrapper {
  flex: 1;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 5.68e-14px;
  max-width: 285px;
  height: 46px;
  padding: 0 8px 0 0;
  display: flex;
  position: relative;
}

.element-game-report .right-wrapper-text {
  color: var(--themeouter-space);
  font-family: var(--theme-inter-regular-13-45-font-family);
  font-size: var(--theme-inter-regular-13-45-font-size);
  font-style: var(--theme-inter-regular-13-45-font-style);
  font-weight: var(--theme-inter-regular-13-45-font-weight);
  letter-spacing: var(--theme-inter-regular-13-45-letter-spacing);
  line-height: var(--theme-inter-regular-13-45-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  text-decoration: underline;
  position: relative;
}

.element-game-report .game-report-middle {
  flex-wrap: wrap;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 15px;
  width: 100%;
  padding: 0 32px;
  display: flex;
  position: relative;
}

.element-game-report .away-team-2 {
  flex-wrap: wrap;
  flex: none;
  align-items: center;
  gap: 10px;
  max-width: 100%;
  height: 80px;
  margin-left: -9.5px;
  margin-right: -9.5px;
  display: inline-flex;
  position: relative;
}

.element-game-report .club-name {
  color: var(--themeswamp);
  font-family: var(--theme-inter-bold-25-9-font-family);
  font-size: 24px;
  font-style: var(--theme-inter-bold-25-9-font-style);
  font-weight: var(--theme-inter-bold-25-9-font-weight);
  letter-spacing: var(--theme-inter-bold-25-9-letter-spacing);
  line-height: var(--theme-inter-bold-25-9-line-height);
  flex: 1;
  position: relative;
}

.element-game-report .club-img-2 {
  background-position: 50%;
  background-size: cover;
  width: 80px;
  max-width: 80px;
  height: 80px;
  position: relative;
}

@media (width <= 500px) {
  .element-game-report .club-img-2 {
    background-size: contain;
    width: 40px;
    height: 40px;
  }

  .element-game-report .stadium-wrapper {
    flex-direction: column-reverse;
    gap: 1px;
  }

  .element-game-report .club-name {
    font-size: 24px;
  }

  .element-game-report .text-wrapper-108 {
    font-size: 16px;
  }

  .element-game-report .referee-wrapper {
    flex-direction: column;
    gap: 0;
  }
}

.element-game-report .score-wrapper {
  justify-content: center;
  align-items: flex-start;
  width: 260px;
  max-width: 260px;
  height: 80px;
  padding: 0 21px;
  display: flex;
  position: relative;
}

.element-game-report .margin {
  flex-direction: column;
  align-items: flex-start;
  width: 74px;
  height: 80px;
  padding: 0 1px;
  display: flex;
  position: relative;
}

.element-game-report .background-2 {
  background-color: #000;
  width: 72px;
  height: 80px;
  position: relative;
}

.element-game-report .container-5 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 72px;
  height: 92px;
  display: flex;
  position: relative;
  top: -6px;
}

.element-game-report .container-6 {
  flex-direction: column;
  flex: none;
  align-items: center;
  width: 72px;
  padding: 7px 19.33px 0;
  display: flex;
  position: relative;
}

.element-game-report .link-10 {
  flex: none;
  justify-content: center;
  align-items: flex-start;
  margin-left: -.33px;
  margin-right: -.33px;
  padding: 1.5px 0;
  display: inline-flex;
  position: relative;
}

.element-game-report .text-wrapper-105 {
  color: var(--themewhite);
  font-family: var(--theme-inter-bold-16-72-upper-font-family);
  font-size: var(--theme-inter-bold-16-72-upper-font-size);
  font-style: var(--theme-inter-bold-16-72-upper-font-style);
  font-weight: var(--theme-inter-bold-16-72-upper-font-weight);
  letter-spacing: var(--theme-inter-bold-16-72-upper-letter-spacing);
  line-height: var(--theme-inter-bold-16-72-upper-line-height);
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.element-game-report .container-7 {
  flex-direction: column;
  flex: none;
  align-items: center;
  width: 72px;
  margin-top: -1px;
  padding: 0 22.36px;
  display: flex;
  position: relative;
}

.element-game-report .text-wrapper-106 {
  color: var(--themewhite);
  font-family: var(--theme-inter-bold-44-font-family);
  font-size: var(--theme-inter-bold-44-font-size);
  font-style: var(--theme-inter-bold-44-font-style);
  font-weight: var(--theme-inter-bold-44-font-weight);
  letter-spacing: var(--theme-inter-bold-44-letter-spacing);
  line-height: var(--theme-inter-bold-44-line-height);
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  margin-left: -.36px;
  margin-right: -.36px;
  position: relative;
}

.element-game-report .background-3 {
  background-color: var(--themeblack);
  width: 72px;
  height: 80px;
  position: relative;
}

.element-game-report .container-8 {
  flex-direction: column;
  flex: none;
  align-items: center;
  width: 72px;
  padding: 7px 20.12px 0 20.13px;
  display: flex;
  position: relative;
}

.element-game-report .link-11 {
  flex: none;
  justify-content: center;
  align-items: flex-start;
  margin-left: -.12px;
  margin-right: -.12px;
  padding: 1.5px 0;
  display: inline-flex;
  position: relative;
}

.element-game-report .text-wrapper-107 {
  color: var(--themewhite);
  font-family: var(--theme-inter-bold-15-94-upper-font-family);
  font-size: var(--theme-inter-bold-15-94-upper-font-size);
  font-style: var(--theme-inter-bold-15-94-upper-font-style);
  font-weight: var(--theme-inter-bold-15-94-upper-font-weight);
  letter-spacing: var(--theme-inter-bold-15-94-upper-letter-spacing);
  line-height: var(--theme-inter-bold-15-94-upper-line-height);
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.element-game-report .text-wrapper-108 {
  color: var(--themewhite);
  font-family: var(--theme-inter-bold-44-font-family);
  font-size: 44px;
  font-style: var(--theme-inter-bold-44-font-style);
  font-weight: var(--theme-inter-bold-44-font-weight);
  letter-spacing: var(--theme-inter-bold-44-letter-spacing);
  line-height: var(--theme-inter-bold-44-line-height);
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  margin-left: -1.86px;
  margin-right: -1.86px;
  position: relative;
}

.element-game-report .game-report-bottom {
  flex-wrap: wrap;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 0;
  width: 100%;
  max-width: 780px;
  padding: 24px 0 0;
  display: flex;
  position: relative;
}

.element-game-report .game-report-match {
  background-color: var(--themewhite);
  flex-direction: column;
  flex: 1;
  align-items: center;
  padding: 16px;
  display: flex;
  position: relative;
}

.element-game-report .divider {
  background-color: var(--themeswamp);
  opacity: .1;
  align-self: stretch;
  width: 100%;
  height: 1px;
  position: relative;
}

.element-game-report .game-setting-wrapper {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.element-game-report .referee-wrapper {
  flex: 1;
  justify-content: flex-end;
  align-self: stretch;
  align-items: center;
  padding: 4px 0;
  display: flex;
  position: relative;
}

.element-game-report .referee-name {
  color: var(--themeouter-space);
  font-family: var(--theme-inter-regular-11-25-font-family);
  font-size: var(--theme-inter-regular-11-25-font-size);
  font-style: var(--theme-inter-regular-11-25-font-style);
  font-weight: var(--theme-inter-regular-11-25-font-weight);
  letter-spacing: var(--theme-inter-regular-11-25-letter-spacing);
  line-height: var(--theme-inter-regular-11-25-line-height);
  text-align: right;
  margin-top: -1px;
  margin-right: 5px;
  position: relative;
}

.setting-wrapper {
  flex: 1;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding: 4px 0;
  display: flex;
  position: relative;
}

.setting-date {
  color: var(--themeouter-space);
  text-align: center;
  font-family: var(--theme-inter-regular-11-25-font-family);
  font-size: 14px;
  font-style: var(--theme-inter-regular-11-25-font-style);
  font-weight: var(--theme-inter-regular-11-25-font-weight);
  letter-spacing: var(--theme-inter-regular-11-25-letter-spacing);
  text-align: center;
  margin-top: -1px;
  position: relative;
}

.element-game-report .stadium-wrapper {
  flex: 1;
  align-self: stretch;
  align-items: center;
  padding: 4px 0;
  display: flex;
  position: relative;
}

.element-game-report .stadium-img {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  padding: 0 8px;
  display: inline-flex;
  position: relative;
}

.element-game-report .dfl-image {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  padding: 4.03px 0 3.97px;
  display: inline-flex;
  position: relative;
}

.element-game-report .DFL-STA-b-png {
  background-image: url("dfl-sta-000006-b-png-3.3337b585.png");
  background-position: 50%;
  background-size: cover;
  width: 29.38px;
  max-width: 29.38px;
  height: 32px;
  position: relative;
}

.element-game-report .stadium-text {
  color: var(--themeouter-space);
  letter-spacing: 0;
  flex: 1;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 11.4px;
  font-weight: 400;
  line-height: 18px;
  text-decoration: underline;
  position: relative;
}

.element-game-report .event-row-instance {
  background-image: url("event-card-player-profile-3.b4ba826c.png") !important;
}

.element-game-report .event-row-2 {
  background-image: url("event-card-team-img-3.2f505740.png") !important;
}

.element-game-report .event-row-3 {
  margin-top: unset !important;
}

.element-game-report .event-row-4 {
  gap: 0 !important;
  padding: 10px 0 !important;
}

.element-game-report .team-detail-squad-17 {
  flex: none;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
  max-width: 1140px;
  padding: 0 10px;
  display: flex;
  position: relative;
}

.element-game-report .team-detail-team-5 {
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  width: 100%;
  display: grid;
}

.element-game-report .team-detail-squad-18 {
  flex-direction: column;
  flex: 1;
  align-items: center;
  max-width: 1140px;
  display: flex;
  position: relative;
}

.element-game-report .container-9 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.element-game-report .team-detail-squad-19 {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.element-game-report .team-detail-squad-header {
  background-image: url("club-img-3.49591c0a.png") !important;
}

.element-game-report .team-detail-squad-20 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.element-game-report .team-detail-squad-instance {
  margin-right: -6.01px !important;
}

.element-game-report .team-detail-squad-cell-instance {
  background-image: url("team-detail-squad-cell-img.ad17cc15.png") !important;
}

.image-wrapper {
  object-fit: contain;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.element-game-report .team-detail-squad-21 {
  margin-left: -155px !important;
}

.element-game-report .team-detail-squad-22 {
  background-image: url("flagicon-1.a44d9bce.png") !important;
}

.element-game-report .team-detail-squad-23 {
  background-image: url("de-png.e24a7c2f.png") !important;
}

.element-game-report .footer-37 {
  background-color: #0000 !important;
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
}

.element-game-report .footer-38 {
  margin-left: -324px !important;
}

.element-gameday-mobile {
  background-color: #f5f5f5;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  display: flex;
  position: relative;
}

.element-gameday-mobile .instance-node-7 {
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
}

.element-gameday-mobile .livescore {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 32px;
  width: 100%;
  max-width: 1200px;
  padding: 0 32px 60px;
  display: flex;
  position: relative;
}

.element-gameday-mobile .dropdown-instance {
  overflow: unset !important;
}

.element-gameday-mobile .dropdown-2 {
  margin-right: unset !important;
  font-weight: 400 !important;
}

.element-gameday-mobile .dropdown-3 {
  font-weight: 400 !important;
}

.element-gameday-mobile .livescore-header {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  max-width: 1400px;
  display: flex;
  position: relative;
}

.element-gameday-mobile .gameday {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  display: flex;
  position: relative;
}

.element-gameday-mobile .gameday-header {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.element-gameday-mobile .gameday-header-date {
  color: var(--themeouter-space);
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 13.9px;
  font-weight: 400;
  line-height: 20px;
  position: relative;
}

.element-gameday-mobile .text-wrapper-91 {
  font-family: var(--theme-inter-bold-13-89-font-family);
  font-size: var(--theme-inter-bold-13-89-font-size);
  font-style: var(--theme-inter-bold-13-89-font-style);
  font-weight: var(--theme-inter-bold-13-89-font-weight);
  letter-spacing: var(--theme-inter-bold-13-89-letter-spacing);
  line-height: var(--theme-inter-bold-13-89-line-height);
  padding-right: 5px;
}

.element-gameday-mobile .text-wrapper-92 {
  color: #34383c;
  font-family: var(--theme-inter-regular-13-89-font-family);
  font-size: var(--theme-inter-regular-13-89-font-size);
  font-style: var(--theme-inter-regular-13-89-font-style);
  font-weight: var(--theme-inter-regular-13-89-font-weight);
  letter-spacing: var(--theme-inter-regular-13-89-letter-spacing);
  line-height: var(--theme-inter-regular-13-89-line-height);
}

.element-gameday-mobile .gameday-header-time {
  color: var(--themeouter-space);
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 13.7px;
  font-weight: 400;
  line-height: 20px;
  position: relative;
}

.element-gameday-mobile .text-wrapper-93 {
  font-family: var(--theme-inter-bold-13-67-font-family);
  font-size: var(--theme-inter-bold-13-67-font-size);
  font-style: var(--theme-inter-bold-13-67-font-style);
  font-weight: var(--theme-inter-bold-13-67-font-weight);
  letter-spacing: var(--theme-inter-bold-13-67-letter-spacing);
  line-height: var(--theme-inter-bold-13-67-line-height);
}

.element-gameday-mobile .text-wrapper-94 {
  color: #34383c;
  font-family: var(--theme-inter-regular-13-67-font-family);
  font-size: var(--theme-inter-regular-13-67-font-size);
  font-style: var(--theme-inter-regular-13-67-font-style);
  font-weight: var(--theme-inter-regular-13-67-font-weight);
  letter-spacing: var(--theme-inter-regular-13-67-letter-spacing);
  line-height: var(--theme-inter-regular-13-67-line-height);
}

.element-gameday-mobile .fixture-data-cell-instance {
  margin-left: unset !important;
  margin-right: unset !important;
  align-self: stretch !important;
  width: 100% !important;
}

.element-gameday-mobile .fixture-data-cell-2 {
  width: unset !important;
  flex: 1 !important;
}

.element-gameday-mobile .fixture-data-cell-3 {
  margin-left: -2.75px !important;
  margin-right: -2.75px !important;
}

.element-gameday-mobile .footer-15 {
  background-color: #0000 !important;
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
}

.element-gameday-mobile .footer-16 {
  margin-left: -324px !important;
}

.element-gameday-mobile .page-frame-2 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  display: flex;
  position: relative;
}

.element-gameday-mobile .livescore-2 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 32px;
  max-width: 1200px;
  padding: 0 32px 60px;
  display: flex;
  position: relative;
}

.element-gameday-mobile .page-header-4 {
  flex: none !important;
  width: 100% !important;
}

.element-gameday-mobile .gamedays {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 32px;
  width: 100%;
  display: flex;
  position: relative;
}

.dropdown {
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  max-width: 1200px;
  display: flex;
  position: relative;
}

.dropdown .dropdown-wrapper {
  flex: none;
  align-items: baseline;
  display: inline-flex;
  position: relative;
}

.dropdown .dropdown-wrapper-2 {
  flex-direction: column;
  align-items: flex-start;
  width: 180px;
  min-height: 56px;
  padding: 24px 0 8px;
  display: flex;
  position: relative;
}

.dropdown .mat-label-select-a-wrapper {
  flex-direction: column;
  align-items: flex-start;
  max-width: 162px;
  padding: 1.5px 0;
  display: flex;
  position: absolute;
  top: 7px;
  left: 0;
  overflow: hidden;
}

.dropdown .mat-label-select-a {
  color: var(--themeblack-60);
  letter-spacing: .29px;
  width: max-content;
  margin-top: -1px;
  margin-right: -2.43px;
  font-family: Inter, Helvetica;
  font-size: 11.2px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.dropdown .dropdown-wrapper-3 {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.dropdown .dropdown-wrapper-4 {
  color: var(--themeblack-87);
  letter-spacing: .29px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 15.6px;
  font-weight: 700;
  line-height: 20px;
  position: relative;
}

.dropdown .dropdown-wrapper-5 {
  width: 10px;
  height: 5px;
  position: relative;
}

.dropdown .dropdown-wrapper-6 {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--themeblack-42);
  align-self: stretch;
  width: 100%;
  max-width: 300px;
  height: 1px;
  position: relative;
}

.dropdown-wrapper-6 {
  border: 1px solid var(--themeblack-42);
  z-index: 10;
  background-color: #fff;
  max-width: 300px;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
}

.dropdown-options {
  max-height: 200px;
  margin: 0;
  padding: 0;
  list-style: none;
  overflow-y: auto;
}

.dropdown-option-item {
  cursor: pointer;
  color: var(--themeblack-87);
  background-color: #fff;
  padding: 15px;
  font-family: Inter, Helvetica;
  font-size: 14px;
}

.dropdown-option-item:hover {
  color: #fff;
  background-color: #000;
}

.dropdown-wrapper-3 {
  cursor: pointer;
  transition: background-color .3s;
}

.fixture-data-cell {
  background-color: #fff;
  align-items: center;
  display: flex;
  position: relative;
}

.fixture-data-cell .fixture-data {
  flex: none;
  align-items: center;
  position: relative;
}

.fixture-data-cell .home-team {
  flex-direction: column;
  align-items: flex-start;
  padding: 0 5px;
  position: relative;
}

@media (width <= 500px) {
  .justRight {
    flex-direction: row-reverse;
  }
}

.fixture-data-cell .gameday-livescore {
  flex: none;
  justify-content: flex-end;
  align-items: center;
  max-width: 1140px;
  display: inline-flex;
  position: relative;
}

.fixture-data-cell .gameday-livescore-2 {
  color: var(--themenevada);
  letter-spacing: 0;
  text-align: right;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  position: relative;
}

.fixture-data-cell .gameday-livescore-wrapper {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 32px;
  height: 32px;
  margin-left: -.01px;
  display: flex;
  position: relative;
}

.fixture-data-cell .gameday-livescore-3 {
  background-position: 50%;
  background-size: cover;
  flex: 1;
  width: 25px;
  height: 25px;
  margin-left: 10px;
  margin-right: 10px;
  position: relative;
}

.fixture-data-cell .schedule-container {
  border: 1px solid #ebe5eb;
  border-radius: 4px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 0 10px;
  padding: 4px;
  display: inline-flex;
  position: relative;
}

.fixture-data-cell .time-string {
  color: #37003c;
  letter-spacing: 0;
  white-space: nowrap;
  height: 24px;
  margin-top: -1px;
  padding: 0 5px;
  font-family: Inter, Helvetica;
  font-size: 15.2px;
  font-weight: 400;
  line-height: 32px;
  position: relative;
}

.fixture-data-cell .away-team {
  flex-direction: column;
  align-items: center;
  padding: 0 5px;
  position: relative;
}

.fixture-data-cell .gameday-livescore-4 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 32px;
  height: 32px;
  display: flex;
  position: relative;
}

.fixture-data-cell .gameday-livescore-5 {
  color: var(--themenevada);
  letter-spacing: 0;
  width: fit-content;
  margin-left: -.01px;
  font-family: Inter, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  position: relative;
}

.fixture-data-cell .staium-wrapper {
  flex: none;
  align-items: center;
  gap: 9px;
  display: inline-flex;
  position: relative;
}

.stadium-wrapper {
  justify-items: center;
  width: 200px;
}

@media (width <= 500px) {
  .stadium-wrapper {
    justify-items: center;
  }

  .element-gameday-mobile .livescore-2 {
    gap: 0;
  }
}

.justRight {
  flex-direction: row-reverse;
}

.fixture-data-cell .stadium-image {
  width: 20px;
  height: 13.75px;
  position: relative;
}

.fixture-data-cell .stadium-location {
  color: #000;
  letter-spacing: -.4px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  position: relative;
}

.fixture-data-cell .fixture-btn-wrapper {
  cursor: pointer;
  flex: none;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.fixture-data-cell .fixture-btn {
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #ebe5eb;
  border-radius: 4px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  display: inline-flex;
  position: relative;
}

.fixture-data-cell .btn-txt {
  color: #37003c;
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.1px;
  position: relative;
}

.fixture-data-cell.state-mobile {
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin: 10px;
  padding: 8px 0;
}

.fixture-data-cell.state-desktop {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 10px 0;
  padding: 8px 32px;
}

.fixture-data-cell.state-mobile .fixture-data {
  justify-content: space-between;
  display: flex;
}

.fixture-data-cell.state-desktop .fixture-data {
  gap: 5px;
  display: inline-flex;
}

.fixture-data-cell.state-desktop .home-team {
  flex: none;
  width: 205px;
  display: inline-flex;
}

.fixture-data-cell.state-mobile .gameday-livescore-2 {
  width: 100px;
  margin-top: -1px;
  margin-right: 5px;
}

.fixture-data-cell.state-desktop .gameday-livescore-2 {
  white-space: nowrap;
}

.fixture-data-cell.state-mobile .away-team {
  display: flex;
}

.fixture-data-cell.state-desktop .away-team {
  flex: none;
  display: inline-flex;
}

.fixture-data-cell.state-mobile .gameday-livescore-5 {
  margin-top: -1px;
}

.fixture-data-cell.state-desktop .gameday-livescore-5 {
  text-align: right;
  white-space: nowrap;
}

.fixture-data-cell .score-container {
  border: 1px solid #ebe5eb;
  border-radius: 4px;
  flex: none;
  place-content: center;
  align-items: center;
  gap: 10px;
  margin: 0 10px;
  padding: 4px;
  display: inline-flex;
  position: relative;
}

.fixture-data-cell .score-string {
  color: #37003c;
  letter-spacing: 0;
  white-space: nowrap;
  text-align: center;
  height: 24px;
  margin-top: -1px;
  padding: 0 10px;
  font-family: Inter, Helvetica;
  font-size: 15.2px;
  font-weight: 400;
  line-height: 32px;
  position: relative;
}

.hero {
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  display: flex;
}

.hero img.hero-image {
  object-fit: cover;
  width: 100%;
  height: auto;
}

.hero .content {
  color: var(--themewhite);
  text-align: center;
  background-color: #000;
  padding: 20px;
}

.hero .subheader {
  margin-top: 0;
  font-family: Inter, Helvetica;
  font-size: 15.2px;
  font-weight: 400;
  line-height: 22.9px;
}

.hero .header-2 {
  font-family: var(--theme-inter-bold-27-75-font-family);
  font-size: var(--theme-inter-bold-27-75-font-size);
  font-weight: var(--theme-inter-bold-27-75-font-weight);
  line-height: var(--theme-inter-bold-27-75-line-height);
  margin-top: 10px;
}

.matchup-cell {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-width: 128px;
  max-width: 1148px;
  height: 121px;
  padding: 0 8px 0 0;
  display: inline-flex;
  position: relative;
}

.matchup-cell .matchup-cell-top {
  flex-direction: column;
  align-items: flex-start;
  width: 124px;
  height: 17.14px;
  display: flex;
  position: relative;
}

.matchup-cell .line {
  background-color: var(--themeswamp);
  width: 128px;
  height: 1px;
  top: 7px;
  left: 4px;
}

.matchup-cell .txt-overlay {
  background-color: var(--themeporcelain);
  width: 96.5px;
  height: 17.14px;
  position: relative;
}

.matchup-cell .date-txt {
  color: var(--themeswamp);
  font-family: var(--theme-inter-regular-12-font-family);
  font-size: var(--theme-inter-regular-12-font-size);
  font-style: var(--theme-inter-regular-12-font-style);
  font-weight: var(--theme-inter-regular-12-font-weight);
  height: 17px;
  letter-spacing: var(--theme-inter-regular-12-letter-spacing);
  line-height: var(--theme-inter-regular-12-line-height);
  white-space: nowrap;
  width: 85px;
  position: absolute;
  top: -2px;
  left: 6px;
}

.matchup-cell .matchup-cell-bottom {
  cursor: pointer;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-end;
  gap: 5.86px;
  width: 100%;
  display: flex;
  position: relative;
}

.matchup-cell .matchup-cell-bottom-2 {
  background-color: var(--themewhite-02);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
  box-shadow: 0 2px 8px #000e140d;
}

.matchup-cell .link-9 {
  background-color: var(--themewhite);
  justify-content: center;
  align-self: stretch;
  align-items: center;
  width: 100%;
  height: 44px;
  padding: 4.5px 10px;
  display: flex;
  position: relative;
}

.matchup-cell .matchup-cell-bottom-wrapper {
  justify-content: center;
  align-items: flex-start;
  width: 40px;
  height: 35px;
  padding: 0 2.5px;
  display: flex;
  position: relative;
}

.matchup-cell .logo {
  background-position: 50%;
  background-size: contain;
  flex: 1;
  align-self: stretch;
  position: relative;
}

.matchup-cell .matchup-cell-bottom-4 {
  flex: none;
  align-items: flex-start;
  padding: 0 5px;
  display: inline-flex;
  position: relative;
}

.matchup-cell .matchup-cell-bottom-5 {
  flex-direction: column;
  align-items: flex-start;
  width: 20px;
  height: 22px;
  padding: 0 1px;
  display: flex;
  position: relative;
}

.matchup-cell .matchup-cell-bottom-6 {
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 22px;
  display: flex;
  position: relative;
}

.matchup-cell .matchup-cell-bottom-7 {
  font-family: var(--theme-inter-bold-18-font-family);
  font-size: var(--theme-inter-bold-18-font-size);
  font-style: var(--theme-inter-bold-18-font-style);
  font-weight: var(--theme-inter-bold-18-font-weight);
  letter-spacing: var(--theme-inter-bold-18-letter-spacing);
  line-height: var(--theme-inter-bold-18-line-height);
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -2px;
  position: relative;
}

.matchup-cell .matchup-cell-bottom-8 {
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 22px;
  display: flex;
  position: relative;
}

.matchup-cell .matchup-cell-bottom-9 {
  font-family: var(--theme-inter-bold-18-font-family);
  font-size: var(--theme-inter-bold-18-font-size);
  font-style: var(--theme-inter-bold-18-font-style);
  font-weight: var(--theme-inter-bold-18-font-weight);
  letter-spacing: var(--theme-inter-bold-18-letter-spacing);
  line-height: var(--theme-inter-bold-18-line-height);
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -2px;
  position: relative;
}

.matchup-cell .background {
  background-color: var(--themewhite);
  justify-content: center;
  align-self: stretch;
  align-items: center;
  width: 100%;
  min-width: 120px;
  height: 36px;
  display: flex;
  position: relative;
}

.matchup-cell .container {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.matchup-cell .vorschau {
  color: var(--themeswamp);
  font-family: var(--theme-inter-bold-10-font-family);
  font-size: var(--theme-inter-bold-10-font-size);
  font-style: var(--theme-inter-bold-10-font-style);
  font-weight: var(--theme-inter-bold-10-font-weight);
  letter-spacing: var(--theme-inter-bold-10-letter-spacing);
  line-height: var(--theme-inter-bold-10-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.matchup-cell .fixture-w-top, .matchup-cell .live-w-top {
  position: absolute;
}

.matchup-cell .fixture-no-top, .matchup-cell .live-top {
  position: relative;
}

.matchup-cell .state-fixture-w-top {
  background-color: var(--themeporcelain);
}

.matchup-cell .state-live-w-top {
  background-color: var(--themetorch-red);
}

.matchup-cell .state-fixture-no-top {
  background-color: var(--themeporcelain);
}

.matchup-cell .state-live-top {
  background-color: var(--themetorch-red);
}

.matchup-cell .state-1-fixture-w-top {
  background-color: var(--themeporcelain);
}

.matchup-cell .state-1-live-w-top {
  background-color: var(--themetorch-red);
}

.matchup-cell .state-1-fixture-no-top {
  background-color: var(--themeporcelain);
}

.matchup-cell .state-1-live-top {
  background-color: var(--themetorch-red);
}

.matchup-cell .state-3-fixture-w-top {
  padding: 7px 57.78px 7px 57.77px;
}

.matchup-cell .state-3-live-w-top {
  padding: 20px;
}

.matchup-cell .state-3-fixture-no-top, .matchup-cell .state-3-live-top {
  padding: 7px 57.78px 7px 57.77px;
}

.matchup-cell .matchup-cell-bottom-6.state-fixture-w-top .matchup-cell-bottom-7 {
  color: var(--themeswamp);
}

.matchup-cell .matchup-cell-bottom-6.state-live-w-top .matchup-cell-bottom-7 {
  color: var(--themewild-sand);
}

.matchup-cell .matchup-cell-bottom-6.state-fixture-no-top .matchup-cell-bottom-7 {
  color: var(--themeswamp);
}

.matchup-cell .matchup-cell-bottom-6.state-live-top .matchup-cell-bottom-7 {
  color: var(--themewild-sand);
}

.matchup-cell .matchup-cell-bottom-8.state-1-fixture-w-top .matchup-cell-bottom-9 {
  color: var(--themeswamp);
}

.matchup-cell .matchup-cell-bottom-8.state-1-live-w-top .matchup-cell-bottom-9 {
  color: var(--themewild-sand);
}

.matchup-cell .matchup-cell-bottom-8.state-1-fixture-no-top .matchup-cell-bottom-9 {
  color: var(--themeswamp);
}

.matchup-cell .matchup-cell-bottom-8.state-1-live-top .matchup-cell-bottom-9 {
  color: var(--themewild-sand);
}

.matchup-cell .background.state-3-fixture-w-top .container {
  margin-left: -19.27px;
  margin-right: -19.28px;
}

.matchup-cell .background.state-3-live-w-top .container {
  margin-top: -13px;
  margin-bottom: -13px;
}

.matchup-cell .background.state-3-fixture-no-top .container {
  margin-left: -19.27px;
  margin-right: -19.28px;
}

.matchup-cell .background.state-3-live-top .container {
  margin-left: -24.27px;
  margin-right: -24.28px;
}

.team-logo {
  object-fit: contain;
  width: 100%;
  height: auto;
}

.background.state-red {
  background-color: var(--themetorch-red);
}

.team-logo {
  object-fit: contain;
  border-radius: 50%;
  width: 100%;
  height: auto;
}

.matchup-cell-bottom-wrapper {
  background-color: #fff;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  display: flex;
  overflow: hidden;
}

.news-article {
  border-color: #e9ebed;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 0 1px;
  display: flex;
  position: relative;
}

.news-article .div-2 {
  background-color: var(--themewhite);
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.news-article .news-article-img {
  object-fit: cover;
  background-color: #e9ebed;
  align-self: stretch;
  width: 100%;
  height: auto;
  min-height: 320px;
  position: relative;
}

.news-article .div-3 {
  flex-direction: column;
  flex: 1;
  align-self: stretch;
  align-items: flex-start;
  gap: 5px;
  width: 100%;
  padding: 20px 10px;
  display: flex;
  position: relative;
}

.news-article .title-3 {
  color: var(--themeswamp);
  font-family: var(--theme-inter-bold-21-font-family);
  font-size: var(--theme-inter-bold-21-font-size);
  font-style: var(--theme-inter-bold-21-font-style);
  font-weight: var(--theme-inter-bold-21-font-weight);
  letter-spacing: var(--theme-inter-bold-21-letter-spacing);
  line-height: var(--theme-inter-bold-21-line-height);
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  align-self: stretch;
  margin-top: -1px;
  display: -webkit-box;
  position: relative;
}

.news-article .subtitle-3 {
  color: var(--themenevada);
  letter-spacing: 0;
  letter-spacing: 0;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  align-self: stretch;
  font-family: Inter, Helvetica;
  font-size: 15.2px;
  font-weight: 400;
  line-height: 22.9px;
  display: -webkit-box;
  position: relative;
  overflow: hidden;
}

.subtitle-3 {
  color: #333;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-size: 14px;
  line-height: 1.6;
  display: -webkit-box;
  overflow: hidden;
}

.subtitle-3 strong {
  font-weight: bold;
}

.subtitle-3 u {
  text-decoration: underline;
}

.subtitle-3 em {
  font-style: italic;
}

.loading-container {
  background-color: #f8f8f8;
  justify-content: center;
  align-items: center;
  height: 100vh;
  display: flex;
}

.loading-text {
  color: #555;
  font-size: 1.5rem;
  font-weight: bold;
}

@media (width <= 390px) {
  .element-homepage-desktop .action-cell-2 {
    grid-template-columns: 1fr;
  }
}

.element-homepage-desktop .button-text {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 11px;
  font-weight: 700;
  line-height: 20px;
  position: relative;
}

.element-homepage-desktop .news-7 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 25px;
  width: 100%;
  max-width: 1200px;
  display: flex;
  position: relative;
}

.element-homepage-desktop .news-container-6 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 24px;
  width: 100%;
  display: flex;
  position: relative;
}

.element-homepage-desktop .page-content-23 {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 0 32px;
  display: flex;
  position: relative;
}

.element-homepage-desktop .page-content-24 {
  color: var(--themeswamp);
  font-family: var(--theme-inter-bold-27-63-upper-font-family);
  font-size: var(--theme-inter-bold-27-63-upper-font-size);
  font-style: var(--theme-inter-bold-27-63-upper-font-style);
  font-weight: var(--theme-inter-bold-27-63-upper-font-weight);
  letter-spacing: var(--theme-inter-bold-27-63-upper-letter-spacing);
  line-height: var(--theme-inter-bold-27-63-upper-line-height);
  text-align: center;
  flex: 1;
  margin-top: -1px;
  position: relative;
}

.element-homepage-desktop .news-container-grid-7 {
  flex-wrap: wrap;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  display: flex;
  position: relative;
}

.element-homepage-desktop .news-article-4 {
  width: 343px !important;
  max-width: 345px !important;
}

.element-homepage-desktop .action-button-10 {
  flex: none !important;
}

.element-homepage-desktop .action-button-11 {
  align-self: unset !important;
  height: 48px !important;
}

.element-homepage-desktop .footer-36 {
  background-color: #0000 !important;
  flex: none !important;
  width: 100% !important;
}

.element-homepage-desktop .news-container-grid-7 {
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  justify-content: center;
  gap: 20px;
  width: 100%;
  display: grid;
}

@media (width <= 800px) {
  .element-homepage-desktop .news-container-grid-7 {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (width >= 801px) {
  .element-homepage-desktop .news-container-grid-7 {
    grid-template-columns: repeat(3, 1fr);
  }
}

.club-cell {
  box-sizing: border-box;
  text-align: center;
  background-color: #fff;
  flex-direction: column;
  align-items: center;
  width: 100px;
  padding: 8px;
  display: flex;
}

.club-cell .img-container {
  object-fit: contain;
  width: 40px;
  height: 40px;
  margin-bottom: 6px;
}

.club-cell .text-container {
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 4px 0;
  display: flex;
}

.club-cell .club-cell-name {
  color: #333;
  word-wrap: break-word;
  overflow-wrap: break-word;
  text-align: center;
  max-width: 100%;
  font-family: Inter, sans-serif;
  font-size: 11px;
  font-weight: 600;
  line-height: 1.2;
  overflow: hidden;
}

.sponsors {
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 1200px;
  display: flex;
  position: relative;
}

.sponsors .sponsors-container {
  background-color: var(--themewhite);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 15px;
  width: 100%;
  padding: 15px 20px;
  display: flex;
  position: relative;
  box-shadow: 0 4px 20px #000e140d;
}

.sponsors .p {
  color: var(--themeouter-space);
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 11.4px;
  font-weight: 400;
  line-height: 14px;
  position: relative;
}

.sponsors .span {
  color: #34383c;
  font-family: var(--theme-inter-regular-11-44-font-family);
  font-size: var(--theme-inter-regular-11-44-font-size);
  font-style: var(--theme-inter-regular-11-44-font-style);
  font-weight: var(--theme-inter-regular-11-44-font-weight);
  letter-spacing: var(--theme-inter-regular-11-44-letter-spacing);
  line-height: var(--theme-inter-regular-11-44-line-height);
}

.sponsors .text-wrapper-2 {
  font-family: var(--theme-inter-bold-11-44-font-family);
  font-size: var(--theme-inter-bold-11-44-font-size);
  font-style: var(--theme-inter-bold-11-44-font-style);
  font-weight: var(--theme-inter-bold-11-44-font-weight);
  letter-spacing: var(--theme-inter-bold-11-44-letter-spacing);
  line-height: var(--theme-inter-bold-11-44-line-height);
}

.sponsors .sponsors-container-2 {
  flex-wrap: wrap;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 15px 50px;
  width: 100%;
  display: flex;
  position: relative;
}

.sponsors .sportsselection {
  object-fit: cover;
  width: 108.78px;
  height: 50px;
  position: relative;
}

.sponsors .image-2 {
  object-fit: cover;
  height: 70px;
  position: relative;
}

.sponsors .image-3 {
  object-fit: cover;
  width: 85px;
  height: 28px;
  position: relative;
}

.sponsors .image-4 {
  object-fit: contain;
  width: 85px;
  height: 80px;
  position: relative;
}

.sponsors .v-white {
  width: 50px;
  height: 47px;
  position: relative;
}

.action-cell {
  flex-direction: column;
  align-items: flex-start;
  width: 457px;
  height: 484px;
  display: flex;
  position: relative;
}

.action-cell .image-wrapper-2 {
  flex: 1;
  align-self: stretch;
  width: 100%;
  position: relative;
}

.action-cell {
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.action-cell .iframe-wrapper {
  flex: 1;
  align-self: stretch;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.action-cell .youtube-iframe {
  border: none;
  width: 100%;
  height: 100%;
}

.action-cell .textwrapper {
  background-color: #fff;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 2px;
  width: 100%;
  padding: 15px 10px;
  display: flex;
  position: relative;
}

.action-cell .title-4 {
  color: var(--themeswamp);
  font-family: var(--theme-inter-bold-21-font-family);
  font-size: var(--theme-inter-bold-21-font-size);
  font-style: var(--theme-inter-bold-21-font-style);
  font-weight: var(--theme-inter-bold-21-font-weight);
  letter-spacing: var(--theme-inter-bold-21-letter-spacing);
  line-height: var(--theme-inter-bold-21-line-height);
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.action-cell .subtitle-4 {
  color: var(--themenevada);
  font-family: var(--theme-inter-regular-15-13-font-family);
  font-size: var(--theme-inter-regular-15-13-font-size);
  font-style: var(--theme-inter-regular-15-13-font-style);
  font-weight: var(--theme-inter-regular-15-13-font-weight);
  letter-spacing: var(--theme-inter-regular-15-13-letter-spacing);
  line-height: var(--theme-inter-regular-15-13-line-height);
  align-self: stretch;
  position: relative;
}

.youtube-iframe {
  width: 10%;
}

.thumbnail-wrapper {
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.play-button-overlay {
  color: #fff;
  background-color: #0009;
  border-radius: 50%;
  padding: 10px 20px;
  font-size: 64px;
}

.leaderboard-highligh {
  flex-direction: column;
  align-items: flex-start;
  max-width: 369.33px;
  height: 180px;
  display: inline-flex;
  position: relative;
}

.leaderboard-highligh .background-shadow-wrapper {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  width: 345.33px;
  display: flex;
  position: relative;
}

.leaderboard-highligh .background-shadow {
  background-color: var(--themewhite);
  flex-direction: column;
  flex: 1;
  align-self: stretch;
  align-items: center;
  gap: 49.16px;
  width: 100%;
  min-height: 180px;
  display: flex;
  position: relative;
  box-shadow: 0 2px 8px #000e140d;
}

.leaderboard-highligh .header {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  width: 345.33px;
  padding: 15.5px 16px 4.34px;
  display: flex;
  position: relative;
}

.leaderboard-highligh .text-wrapper-15 {
  color: var(--themeblack);
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 17px;
  font-weight: 700;
  line-height: 24px;
  position: relative;
}

.leaderboard-highligh .player-detail-wrapper {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 151px;
  display: inline-flex;
  position: absolute;
  top: 29px;
  left: 210px;
}

.leaderboard-highligh .team-detail {
  align-self: stretch;
  width: 100%;
  height: 25px;
  position: relative;
}

.leaderboard-highligh .player-detail {
  align-self: stretch;
  width: 100%;
  height: 147px;
  position: relative;
}

.leaderboard-highligh .div-5 {
  flex: none;
  align-items: flex-end;
  width: 345.33px;
  padding: 1px 17px 9px;
  display: flex;
  position: relative;
}

.leaderboard-highligh .div-6 {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.leaderboard-highligh .div-7 {
  width: 50px;
  height: 25px;
  position: relative;
}

.leaderboard-highligh .div-7 img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.leaderboard-highligh .div-8 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  display: flex;
  position: relative;
}

.leaderboard-highligh .div-9 {
  color: var(--themeblack);
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
}

.leaderboard-highligh .text-wrapper-16 {
  color: #000;
  letter-spacing: 0;
  font-family: Inter, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.leaderboard-highligh .text-wrapper-17 {
  font-weight: 700;
}

.leaderboard-highligh .leaderboard-highligh-wrapper {
  margin-left: --11.4e-14px;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.leaderboard-highligh .text-wrapper-18 {
  color: var(--themeblack);
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 32px;
  font-weight: 700;
  line-height: 32px;
  position: relative;
}

.leaderboard-stat {
  background-color: var(--themewhite);
  border-color: #e9ebed;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  padding: 9.14px 16px 10.16px;
  display: flex;
  position: relative;
}

.leaderboard-stat .left-container-5 {
  flex: none;
  align-items: center;
  gap: 5px;
  display: inline-flex;
  position: relative;
}

.leaderboard-stat .image-10 {
  object-fit: contain;
  width: 35px;
  height: 35px;
  position: relative;
}

.leaderboard-stat .name-5 {
  color: var(--themeswamp);
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 13.9px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
}

.leaderboard-stat .text-wrapper-19 {
  color: #000e14;
  letter-spacing: 0;
  font-family: Inter, Helvetica;
  font-size: 13.9px;
  font-weight: 400;
  line-height: 24px;
}

.leaderboard-stat .text-wrapper-20 {
  font-weight: 700;
}

.leaderboard-stat .right-container {
  align-items: center;
  gap: 5px;
  min-width: 100px;
  max-width: 369.33px;
  display: flex;
  position: relative;
}

.leaderboard-stat .team-logo-wrapper {
  justify-content: flex-end;
  align-items: center;
  width: 24px;
  display: flex;
  position: relative;
}

.leaderboard-stat .team-logo-3 {
  background-image: url("team-logo-1.2794d46a.png");
  background-position: 50%;
  background-size: cover;
  flex: 1;
  height: 24px;
  position: relative;
}

.leaderboard-stat .team-name-4 {
  flex: none;
  align-items: center;
  display: inline-flex;
  position: relative;
}

.leaderboard-stat .team-name-5 {
  color: var(--themeswamp);
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 13.7px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
}

.leaderboard-stat .count {
  color: var(--themeswamp);
  letter-spacing: 0;
  text-align: center;
  flex: 1;
  justify-content: right;
  align-items: center;
  gap: 8px;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  display: flex;
  position: relative;
}

.leaderboard-stat.property-1-4-desktop {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 0;
  width: 1149px;
}

.leaderboard-stat.property-1-4-mobile {
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  width: 390px;
}

.leaderboard-stat.property-1-4-desktop .right-container {
  flex: 1;
}

.leaderboard-stat.property-1-4-mobile .right-container {
  flex: none;
  align-self: stretch;
  width: 100%;
}

.element-leaderboard {
  background-color: #f5f5f5;
  flex-direction: column;
  align-items: center;
  gap: 60px;
  display: flex;
  position: relative;
}

.element-leaderboard .instance-node-10 {
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
}

.element-leaderboard .page-control-4 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  padding: 0 32px;
  display: flex;
  position: relative;
}

.element-leaderboard .leaderboard {
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  height: 56px;
  padding: 0 0 8px;
  display: flex;
  position: relative;
}

.element-leaderboard .leaderboard-wrapper {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  padding: 0 8px 0 0;
  display: inline-flex;
  position: relative;
}

.element-leaderboard .leaderboard-2 {
  flex: 1;
  align-items: flex-end;
  padding: 0 8px;
  display: inline-flex;
  position: relative;
}

.element-leaderboard .leaderboard-3 {
  flex-direction: column;
  align-items: flex-start;
  width: 44px;
  padding: 8px 0;
  display: flex;
  position: relative;
}

.element-leaderboard .leaderboard-4 {
  color: #bababa;
  letter-spacing: 1px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 11.1px;
  font-weight: 700;
  line-height: 18px;
  position: relative;
}

.element-leaderboard .leaderboard-5 {
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-color: var(--themeswamp);
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.element-leaderboard .leaderboard-6 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  padding: 8px 0;
  display: flex;
  position: relative;
}

.element-leaderboard .leaderboard-7 {
  color: var(--themeswamp);
  letter-spacing: 1px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 11.5px;
  font-weight: 700;
  line-height: 18px;
  position: relative;
}

.element-leaderboard .leaderboard-highligh-2 {
  flex-wrap: wrap;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  display: flex;
  position: relative;
}

.element-leaderboard .class-11 {
  flex: none !important;
  margin-right: -19.33px !important;
}

.element-leaderboard .class-12 {
  flex: none !important;
}

.element-leaderboard .single-stat-cells {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  gap: 10px;
  width: 100%;
  display: flex;
  position: relative;
}

.element-leaderboard .footer-23 {
  background-color: #0000 !important;
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
}

.element-leaderboard .footer-24 {
  margin-left: -324px !important;
}

.element-leaderboard .segButton {
  color: var(--themeswamp);
  font-family: var(--theme-inter-bold-15-75-upper-font-family);
  font-size: var(--theme-inter-bold-15-75-upper-font-size);
  font-style: var(--theme-inter-bold-15-75-upper-font-style);
  font-weight: var(--theme-inter-bold-15-75-upper-font-weight);
  letter-spacing: var(--theme-inter-bold-15-75-upper-letter-spacing);
  line-height: var(--theme-inter-bold-15-75-upper-line-height);
  background-color: #0000;
  border: none;
  align-self: stretch;
  position: relative;
}

.segButtonActive {
  color: var(--themeswamp);
  font-family: var(--theme-inter-bold-15-75-upper-font-family);
  font-size: var(--theme-inter-bold-15-75-upper-font-size);
  font-style: var(--theme-inter-bold-15-75-upper-font-style);
  font-weight: var(--theme-inter-bold-15-75-upper-font-weight);
  letter-spacing: var(--theme-inter-bold-15-75-upper-letter-spacing);
  line-height: var(--theme-inter-bold-15-75-upper-line-height);
  background-color: #0000;
  border: none;
  align-self: stretch;
  text-decoration-line: underline;
  position: relative;
}

.league-selection {
  background-color: var(--themewhite);
  cursor: pointer;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  max-width: 360px;
  padding: 17px 15px;
  display: flex;
  position: relative;
  transform: rotate(180deg);
}

.league-selection:hover {
  color: #fff;
}

.league-selection .name {
  color: var(--themeswamp);
  font-family: var(--theme-inter-bold-20-63-font-family);
  font-size: var(--theme-inter-bold-20-63-font-size);
  font-style: var(--theme-inter-bold-20-63-font-style);
  font-weight: var(--theme-inter-bold-20-63-font-weight);
  letter-spacing: var(--theme-inter-bold-20-63-letter-spacing);
  line-height: var(--theme-inter-bold-20-63-line-height);
  align-self: stretch;
  position: relative;
  transform: rotate(180deg);
}

.league-selection .subtitle {
  color: var(--themeouter-space);
  font-family: var(--theme-inter-bold-13-upper-font-family);
  font-size: var(--theme-inter-bold-13-upper-font-size);
  font-style: var(--theme-inter-bold-13-upper-font-style);
  font-weight: var(--theme-inter-bold-13-upper-font-weight);
  letter-spacing: var(--theme-inter-bold-13-upper-letter-spacing);
  line-height: var(--theme-inter-bold-13-upper-line-height);
  align-self: stretch;
  margin-top: -1px;
  position: relative;
  transform: rotate(180deg);
}

.element-league-selection {
  background-color: #f5f5f5;
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
}

.element-league-selection .instance-node {
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
}

.element-league-selection .page-header-wrapper {
  background-color: #000;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 32px;
  display: flex;
  position: relative;
}

.element-league-selection .page-header-2 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  max-width: 1200px;
  padding: 0 175px;
  display: flex;
  position: relative;
}

@media (width <= 800px) {
  .element-league-selection .page-header-2 {
    padding: 0;
  }
}

.element-league-selection .page-header-wrapper-2 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  max-width: 700px;
  display: flex;
  position: relative;
}

.element-league-selection .page-header-wrapper-3 {
  color: var(--themewhite);
  font-family: var(--theme-inter-black-37-66-font-family);
  font-size: var(--theme-inter-black-37-66-font-size);
  font-style: var(--theme-inter-black-37-66-font-style);
  font-weight: var(--theme-inter-black-37-66-font-weight);
  letter-spacing: var(--theme-inter-black-37-66-letter-spacing);
  line-height: var(--theme-inter-black-37-66-line-height);
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.element-league-selection .page-header-wrapper-4 {
  color: var(--themewhite-75);
  letter-spacing: 0;
  align-self: stretch;
  font-family: Inter, Helvetica;
  font-size: 15.8px;
  font-weight: 700;
  line-height: 28px;
  position: relative;
}

.element-league-selection .page-content {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  width: 100%;
  max-width: 1200px;
  padding: 60px 0;
  display: flex;
  position: relative;
}

.element-league-selection .leagues-wrapper {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 18px;
  width: 100%;
  max-width: 1200px;
  display: flex;
  position: relative;
}

.element-league-selection .instance-node-2 {
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
  padding: 0 16px !important;
}

.element-league-selection .league-cell-list {
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  place-items: center stretch;
  gap: 18px;
  width: 100%;
  max-width: 1200px;
  padding: 0 16px 30px;
  display: grid;
}

.element-league-selection .league-selection-cell {
  flex-grow: 1;
  width: 100%;
  min-width: 260px;
}

.element-league-selection .footer-instance {
  background-color: #0000 !important;
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
}

.element-league-selection .footer-2 {
  gap: 20px 10px !important;
}

.element-league-selection .footer-3 {
  margin-left: -324px !important;
}

.element-league-selection .div-14 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  display: flex;
  position: relative;
}

.element-league-selection .page-header-3 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 10px;
  width: 836px;
  max-width: 1200px;
  padding: 0 32px;
  display: flex;
  position: relative;
}

.element-league-selection .page-content-2 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 60px 0;
  display: flex;
  position: relative;
}

.element-league-selection .leagues-wrapper-2 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 18px;
  width: 900px;
  max-width: 1200px;
  padding: 0 32px;
  display: flex;
  position: relative;
}

.element-league-selection .league-cell-list-2 {
  box-sizing: border-box;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  align-items: stretch;
  gap: 18px;
  width: 100%;
  max-width: 1200px;
  padding: 0 0 120px;
  display: grid;
}

.element-league-selection .sponsors-instance {
  align-items: center !important;
}

.element-league-selection .sponsors-3 {
  flex: none !important;
  justify-content: center !important;
  align-items: center !important;
  width: 900px !important;
  padding: 0 16px !important;
}

.no-results-text {
  color: #000;
  text-align: left;
  margin: 20px 0;
  font-family: Inter, Helvetica;
  font-size: 16px;
  font-weight: 700;
}

.element-news-detail {
  background-color: #f5f5f5;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  display: flex;
  position: relative;
}

.element-news-detail .navigation-desktop-14 {
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
}

.element-news-detail .news-detail {
  flex-wrap: wrap;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 0;
  width: 100%;
  display: flex;
  position: relative;
}

.element-news-detail .news-detail-wrapper {
  background-color: var(--themewhite);
  flex-direction: column;
  flex: 1;
  align-items: center;
  max-width: 1140px;
  padding: 32px 0;
  display: flex;
  position: relative;
  overflow-y: scroll;
}

.element-news-detail .news-detail-content {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.element-news-detail .news-detail-content-2 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.element-news-detail .news-detail-content-3 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  max-width: 782px;
  padding: 20px 32px;
  display: flex;
  position: relative;
}

.element-news-detail .news-detail-content-4 {
  color: var(--themeguardsman-red);
  font-family: var(--theme-inter-regular-11-63-upper-font-family);
  font-size: 18px;
  font-style: var(--theme-inter-regular-11-63-upper-font-style);
  font-weight: var(--theme-inter-regular-11-63-upper-font-weight);
  letter-spacing: var(--theme-inter-regular-11-63-upper-letter-spacing);
  line-height: var(--theme-inter-regular-11-63-upper-line-height);
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.element-news-detail .news-detail-content-5 {
  color: var(--themeswamp);
  font-family: var(--theme-inter-regular-40-font-family);
  font-size: var(--theme-inter-regular-40-font-size);
  font-style: var(--theme-inter-regular-40-font-style);
  font-weight: var(--theme-inter-regular-40-font-weight);
  letter-spacing: var(--theme-inter-regular-40-letter-spacing);
  line-height: var(--theme-inter-regular-40-line-height);
  align-self: stretch;
  position: relative;
}

.element-news-detail .news-detail-content-6 {
  color: var(--themeoslo-gray);
  font-family: var(--theme-inter-regular-12-upper-font-family);
  font-size: var(--theme-inter-regular-12-upper-font-size);
  font-style: var(--theme-inter-regular-12-upper-font-style);
  font-weight: var(--theme-inter-regular-12-upper-font-weight);
  letter-spacing: var(--theme-inter-regular-12-upper-letter-spacing);
  line-height: var(--theme-inter-regular-12-upper-line-height);
  align-self: stretch;
  position: relative;
}

.element-news-detail .hero-img {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 4px;
  width: 100%;
  max-width: 1400px;
  padding: 0 0 24px;
  display: flex;
  position: relative;
}

.element-news-detail .image-12 {
  aspect-ratio: 16 / 9;
  background-position: center;
  background-size: cover;
  width: 100%;
  max-width: 1200px;
  height: auto;
}

.element-news-detail .news-detail-content-7 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  padding: 0 10px;
  display: flex;
  position: relative;
}

.element-news-detail .news-detail-content-8 {
  color: var(--themeouter-space);
  letter-spacing: 0;
  align-self: stretch;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 22px;
  font-weight: 400;
  line-height: 32px;
  position: relative;
}

.element-news-detail .text-wrapper-89 {
  font-weight: 700;
}

.element-news-detail .text-wrapper-90 {
  color: #34383c;
  letter-spacing: 0;
  font-family: Inter, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}

.element-news-detail .footer-13 {
  background-color: #0000 !important;
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
}

.element-news-detail .footer-14 {
  margin-left: -324px !important;
}

.element-news-detail .content-frame-2 {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  display: flex;
  position: relative;
}

.element-news-detail .news-detail-2 {
  flex-wrap: wrap;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  gap: 0;
  display: flex;
  position: relative;
}

.news-detail-content-8 {
  color: #333;
  font-size: 16px;
  line-height: 1.6;
}

.news-detail-content-8 p {
  margin-bottom: 15px;
}

.news-detail-content-8 strong {
  font-weight: bold;
}

.news-detail-content-8 em {
  font-style: italic;
}

.news-detail-content-8 u {
  text-decoration: underline;
}

.news-detail-content-8 ul, .news-detail-content-8 ol {
  margin-bottom: 15px;
  padding-left: 20px;
}

.news-detail-content-8 h1, .news-detail-content-8 h2, .news-detail-content-8 h3 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: bold;
}

.element-homepage-desktop .action-cell-2 {
  flex-wrap: wrap;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 22px;
  width: 100%;
  max-width: 1200px;
  display: flex;
  position: relative;
}

.element-homepage-desktop .action-cell-instance {
  min-width: 320px !important;
  width: unset !important;
  flex: 1 !important;
}

@media (width <= 800px) {
  .news-container-grid-7 {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (width >= 801px) {
  .news-container-grid-7 {
    grid-template-columns: repeat(3, 1fr);
  }
}

.stat-cell {
  background-color: #fff;
  align-items: flex-start;
  width: 380px;
  max-width: 1140px;
  display: flex;
  position: relative;
}

.stat-cell .stat-cell-key {
  flex: 1;
  align-items: center;
  max-width: 242.67px;
  height: 47px;
  padding: 13px 16px 14px;
  display: flex;
  position: relative;
}

.stat-cell .stat-cell-key-text {
  color: var(--themeouter-space);
  font-family: var(--theme-inter-regular-13-67-font-family);
  font-size: var(--theme-inter-regular-13-67-font-size);
  font-style: var(--theme-inter-regular-13-67-font-style);
  font-weight: var(--theme-inter-regular-13-67-font-weight);
  letter-spacing: var(--theme-inter-regular-13-67-letter-spacing);
  line-height: var(--theme-inter-regular-13-67-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.stat-cell .stat-cell-value {
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  max-width: 121.33px;
  height: 47px;
  padding: 13px 16px 14px;
  display: flex;
  position: relative;
}

.stat-cell .stat-cell-value-text {
  color: var(--themeouter-space);
  font-family: var(--theme-inter-bold-14-font-family);
  font-size: var(--theme-inter-bold-14-font-size);
  font-style: var(--theme-inter-bold-14-font-style);
  font-weight: var(--theme-inter-bold-14-font-weight);
  letter-spacing: var(--theme-inter-bold-14-letter-spacing);
  line-height: var(--theme-inter-bold-14-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.element-player-detail {
  background-color: #f5f5f5;
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
}

.element-player-detail .class-13 {
  margin-right: unset !important;
}

.element-player-detail .instance-node-11 {
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
}

.element-player-detail .player-detail-header {
  flex-wrap: wrap;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 0;
  width: 100%;
  display: flex;
  position: relative;
}

.element-player-detail .player-detail-header-2 {
  background-color: #dcc91e;
  flex: 1;
  justify-content: center;
  max-width: 100%;
  display: flex;
  position: relative;
}

.element-player-detail .player-detail-header-3 {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  max-width: 500px;
  padding: 32px 0 0;
  display: flex;
  position: relative;
}

.element-player-detail .large-text-container {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 5px;
  width: 100%;
  max-width: 1140px;
  padding: 0 0 60px;
  display: flex;
  position: relative;
}

.element-player-detail .large-text-container-2 {
  color: #000;
  font-family: var(--theme-inter-bold-80-font-family);
  font-size: var(--theme-inter-bold-80-font-size);
  font-style: var(--theme-inter-bold-80-font-style);
  font-weight: var(--theme-inter-bold-80-font-weight);
  height: 80px;
  letter-spacing: var(--theme-inter-bold-80-letter-spacing);
  line-height: var(--theme-inter-bold-80-line-height);
  white-space: nowrap;
  margin-top: -1px;
  position: relative;
}

.element-player-detail .large-text-container-3 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.element-player-detail .large-text-container-wrapper {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.element-player-detail .large-text-container-4 {
  color: #000;
  font-family: var(--theme-inter-regular-26-75-font-family);
  font-size: var(--theme-inter-regular-26-75-font-size);
  font-style: var(--theme-inter-regular-26-75-font-style);
  font-weight: var(--theme-inter-regular-26-75-font-weight);
  letter-spacing: var(--theme-inter-regular-26-75-letter-spacing);
  line-height: var(--theme-inter-regular-26-75-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.element-player-detail .large-text-container-5 {
  color: #000;
  font-family: var(--theme-inter-bold-27-88-font-family);
  font-size: var(--theme-inter-bold-27-88-font-size);
  font-style: var(--theme-inter-bold-27-88-font-style);
  font-weight: var(--theme-inter-bold-27-88-font-weight);
  letter-spacing: var(--theme-inter-bold-27-88-letter-spacing);
  line-height: var(--theme-inter-bold-27-88-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.element-player-detail .small-text-container {
  flex-wrap: wrap;
  flex: none;
  align-items: center;
  gap: 0;
  max-width: 400px;
  padding: 0 30px 30px;
  display: flex;
  position: relative;
}

.element-player-detail .small-text-container-2 {
  flex-wrap: wrap;
  flex: 1;
  align-items: center;
  gap: 0;
  display: flex;
  position: relative;
}

.element-player-detail .small-text-cell {
  flex: none;
  align-items: center;
  gap: 6px;
  min-width: 160px;
  height: 37px;
  padding: 8px 0;
  display: inline-flex;
  position: relative;
}

.element-player-detail .small-text-cell-key {
  color: #141212;
  font-family: var(--theme-inter-regular-11-05-font-family);
  font-size: var(--theme-inter-regular-11-05-font-size);
  font-style: var(--theme-inter-regular-11-05-font-style);
  font-weight: var(--theme-inter-regular-11-05-font-weight);
  letter-spacing: var(--theme-inter-regular-11-05-letter-spacing);
  line-height: var(--theme-inter-regular-11-05-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.element-player-detail .small-text-cell-2 {
  color: #141212;
  font-family: var(--theme-inter-bold-12-03-font-family);
  font-size: var(--theme-inter-bold-12-03-font-size);
  font-style: var(--theme-inter-bold-12-03-font-style);
  font-weight: var(--theme-inter-bold-12-03-font-weight);
  letter-spacing: var(--theme-inter-bold-12-03-letter-spacing);
  line-height: var(--theme-inter-bold-12-03-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.element-player-detail .de-png {
  border: 1px solid;
  border-color: var(--themegallery);
  background-image: url("flagicon-2.46f9c028.png");
  background-position: 50%;
  background-size: cover;
  width: 20.66px;
  height: 16px;
  position: relative;
}

.element-player-detail .small-text-cell-3, .element-player-detail .small-text-cell-4, .element-player-detail .small-text-cell-5, .element-player-detail .small-text-cell-6, .element-player-detail .small-text-cell-7 {
  flex: none;
  align-items: center;
  gap: 6px;
  min-width: 270px;
  height: 37px;
  padding: 8px 0;
  display: inline-flex;
  position: relative;
}

.element-player-detail .player-detail-header-4 {
  flex: none;
  align-items: flex-end;
  display: inline-flex;
  position: relative;
}

.element-player-detail .player-detail-2 {
  align-self: stretch;
  width: 232px;
  min-height: 120px;
  position: relative;
}

.element-player-detail .player-detail-3 {
  flex: none;
  justify-content: flex-end;
  align-self: stretch;
  align-items: flex-start;
  margin-left: -120px;
  padding: 25px 0;
  display: inline-flex;
  position: relative;
}

.element-player-detail .player-detail-4 {
  object-fit: cover;
  height: 70px;
  position: absolute;
  top: 30px;
  left: 100px;
}

@media (width <= 768px) {
  .element-player-detail .player-detail-4 {
    z-index: 1;
    left: 50px;
  }
}

.element-player-detail .player-detail-5 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  padding: 25px 0;
  display: inline-flex;
  position: relative;
}

.element-player-detail .player-detail-6 {
  object-fit: cover;
  align-self: stretch;
  width: 232px;
  margin-left: -80px;
  position: relative;
}

.element-player-detail .page-content-10 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 30px;
  width: 100%;
  display: flex;
  position: relative;
}

.element-player-detail .page-content-section {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  display: flex;
  position: relative;
}

.element-player-detail .page-content-11 {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 0 32px;
  display: flex;
  position: relative;
}

.element-player-detail .page-content-12 {
  color: var(--themeswamp);
  font-family: var(--theme-inter-bold-27-63-upper-font-family);
  font-size: var(--theme-inter-bold-27-63-upper-font-size);
  font-style: var(--theme-inter-bold-27-63-upper-font-style);
  font-weight: var(--theme-inter-bold-27-63-upper-font-weight);
  letter-spacing: var(--theme-inter-bold-27-63-upper-letter-spacing);
  line-height: var(--theme-inter-bold-27-63-upper-line-height);
  text-align: center;
  flex: 1;
  margin-top: -1px;
  position: relative;
}

.element-player-detail .club-stats {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.element-player-detail .club-stats-container {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  max-width: 1140px;
  display: flex;
  position: relative;
}

.element-player-detail .club-stats-content {
  flex-wrap: wrap;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 5px 10px;
  width: 100%;
  padding: 20px 0;
  display: flex;
  position: relative;
}

.element-player-detail .stat-cell-instance {
  min-width: 300px !important;
  width: unset !important;
  flex: 1 !important;
}

.element-player-detail .page-content-section-2 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 30px;
  width: 100%;
  display: flex;
  position: relative;
}

.element-player-detail .gameday-wrapper {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  display: flex;
  position: relative;
}

.element-player-detail .gameday-2 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  gap: 10px;
  width: 100%;
  display: flex;
  position: relative;
}

.element-player-detail .gameday-header-2 {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.element-player-detail .gameday-header-date-2 {
  color: var(--themeouter-space);
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 13.9px;
  font-weight: 400;
  line-height: 20px;
  position: relative;
}

.element-player-detail .text-wrapper-95 {
  font-family: var(--theme-inter-bold-13-89-font-family);
  font-size: var(--theme-inter-bold-13-89-font-size);
  font-style: var(--theme-inter-bold-13-89-font-style);
  font-weight: var(--theme-inter-bold-13-89-font-weight);
  letter-spacing: var(--theme-inter-bold-13-89-letter-spacing);
  line-height: var(--theme-inter-bold-13-89-line-height);
}

.element-player-detail .text-wrapper-96 {
  color: #34383c;
  font-family: var(--theme-inter-regular-13-89-font-family);
  font-size: var(--theme-inter-regular-13-89-font-size);
  font-style: var(--theme-inter-regular-13-89-font-style);
  font-weight: var(--theme-inter-regular-13-89-font-weight);
  letter-spacing: var(--theme-inter-regular-13-89-letter-spacing);
  line-height: var(--theme-inter-regular-13-89-line-height);
}

.element-player-detail .gameday-header-time-2 {
  color: var(--themeouter-space);
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 13.7px;
  font-weight: 400;
  line-height: 20px;
  position: relative;
}

.element-player-detail .text-wrapper-97 {
  font-family: var(--theme-inter-bold-13-67-font-family);
  font-size: var(--theme-inter-bold-13-67-font-size);
  font-style: var(--theme-inter-bold-13-67-font-style);
  font-weight: var(--theme-inter-bold-13-67-font-weight);
  letter-spacing: var(--theme-inter-bold-13-67-letter-spacing);
  line-height: var(--theme-inter-bold-13-67-line-height);
}

.element-player-detail .text-wrapper-98 {
  color: #34383c;
  font-family: var(--theme-inter-regular-13-67-font-family);
  font-size: var(--theme-inter-regular-13-67-font-size);
  font-style: var(--theme-inter-regular-13-67-font-style);
  font-weight: var(--theme-inter-regular-13-67-font-weight);
  letter-spacing: var(--theme-inter-regular-13-67-letter-spacing);
  line-height: var(--theme-inter-regular-13-67-line-height);
}

.element-player-detail .class-14 {
  margin-left: unset !important;
  margin-right: unset !important;
}

.element-player-detail .gameday-3 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  display: flex;
  position: relative;
}

.element-player-detail .action-button-wrapper {
  justify-content: center;
  align-items: flex-start;
  width: 180px;
  max-width: 180px;
  height: 48px;
  display: flex;
  position: relative;
}

.element-player-detail .action-button-3 {
  background-color: #000;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  padding: 0 16px;
  display: flex;
  position: relative;
}

.element-player-detail .button-title-3 {
  color: var(--themewhite);
  font-family: var(--theme-inter-bold-11-38-upper-font-family);
  font-size: var(--theme-inter-bold-11-38-upper-font-size);
  font-style: var(--theme-inter-bold-11-38-upper-font-style);
  font-weight: var(--theme-inter-bold-11-38-upper-font-weight);
  letter-spacing: var(--theme-inter-bold-11-38-upper-letter-spacing);
  line-height: var(--theme-inter-bold-11-38-upper-line-height);
  text-align: center;
  flex: 1;
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.element-player-detail .news-3 {
  flex: none;
  align-self: stretch;
  width: 100%;
  display: flex;
  position: relative;
}

.element-player-detail .news-4 {
  flex-direction: column;
  align-items: center;
  gap: 25px;
  max-width: 1200px;
  display: flex;
  position: relative;
}

.element-player-detail .news-container-2 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 24px;
  width: 100%;
  display: flex;
  position: relative;
}

.element-player-detail .page-content-13 {
  flex: none;
  align-self: stretch;
  width: 100%;
  display: flex;
  position: relative;
}

.element-player-detail .page-content-14 {
  color: var(--themeswamp);
  flex: 1;
  margin-top: -1px;
  position: relative;
}

.element-player-detail .news-container-grid-3 {
  flex-wrap: wrap;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  display: flex;
  position: relative;
}

.element-player-detail .class-15 {
  margin-right: -34px !important;
}

.element-player-detail .action-button-instance-wrapper {
  flex: none;
  justify-content: center;
  align-items: flex-start;
  width: 180px;
  max-width: 180px;
  display: flex;
  position: relative;
}

.element-player-detail .action-button-4 {
  align-self: unset !important;
  height: 48px !important;
}

.element-player-detail .action-button-5 {
  flex: none !important;
}

.element-player-detail .news-container-3 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.element-player-detail .title-7 {
  color: var(--themeswamp);
  font-family: var(--theme-inter-bold-16-03-upper-font-family);
  font-size: var(--theme-inter-bold-16-03-upper-font-size);
  font-style: var(--theme-inter-bold-16-03-upper-font-style);
  font-weight: var(--theme-inter-bold-16-03-upper-font-weight);
  letter-spacing: var(--theme-inter-bold-16-03-upper-letter-spacing);
  line-height: var(--theme-inter-bold-16-03-upper-line-height);
  flex: 1;
  margin-top: -1px;
  position: relative;
}

.element-player-detail .news-container-grid-4 {
  flex-wrap: wrap;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  display: flex;
  position: relative;
}

.element-player-detail .footer-25 {
  background-color: #0000 !important;
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
}

.element-player-detail .class-16 {
  margin-left: -324px !important;
}

@media (width <= 768px) {
  .news-grid {
    grid-template-columns: 1fr;
  }
}

@media (width >= 600px) {
  .stats-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (width >= 1024px) {
  .stats-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (width >= 600px) {
  .team-squad {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (width >= 1024px) {
  .team-squad {
    grid-template-columns: repeat(3, 1fr);
  }
}

.element-register {
  background-color: #f5f5f5;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  display: flex;
  position: relative;
}

.element-register .navigation-desktop-13 {
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
}

.element-register .authentication-form {
  background-color: var(--themewhite);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 21px;
  width: 100vw;
  max-width: 570px;
  padding: 30px;
  display: flex;
  position: relative;
}

.element-register .div-15 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.element-register .authentication-state {
  align-self: stretch;
  align-items: center;
  width: 100%;
  height: 24px;
  display: flex;
  position: relative;
}

.element-register .authentication-state-2 {
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 24px;
  padding: 11px 253px;
  display: flex;
  position: relative;
}

.element-register .authentication-state-wrapper {
  flex-direction: column;
  align-items: flex-start;
  width: 32px;
  height: 2px;
  margin-left: -130px;
  margin-right: -98px;
  padding: 0 4px;
  display: flex;
  position: relative;
}

.element-register .authentication-state-3 {
  background-color: var(--themeswamp);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--themeswamp);
  border-top-style: solid;
  border-top-width: 1px;
  width: 24px;
  height: 2px;
  position: relative;
}

.element-register .authentication-state-4 {
  flex-direction: column;
  align-items: flex-start;
  width: 32px;
  height: 1px;
  margin-left: -98px;
  margin-right: -130px;
  padding: 0 4px;
  display: flex;
  position: relative;
}

.element-register .authentication-state-5 {
  background-color: var(--themeswamp);
  opacity: .4;
  width: 24px;
  height: 1px;
  position: relative;
}

.element-register .div-wrapper-3 {
  flex-wrap: wrap;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 0 10px;
  width: 100%;
  display: flex;
  position: relative;
}

.element-register .authentication {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 5px;
  max-width: 570px;
  padding: 32px 0;
  display: flex;
  position: relative;
}

.element-register .authentication-2 {
  color: var(--themeswamp);
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 38px;
  font-weight: 900;
  line-height: 48px;
  position: relative;
}

.element-register .authentication-3 {
  color: var(--themenevada);
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  font-family: Inter, Helvetica;
  font-size: 15.1px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
}

.element-register .authentication-state-6 {
  flex-wrap: wrap;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 0;
  width: 100%;
  min-height: 48px;
  display: flex;
  position: relative;
}

.element-register .authentication-state-7 {
  flex-direction: column;
  flex: 1;
  align-self: stretch;
  align-items: flex-start;
  max-width: 570px;
  display: flex;
  position: relative;
}

.element-register .authentication-state-8 {
  border: 2px solid;
  border-color: var(--themesilver-sand);
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  height: 36px;
  padding: 2px;
  display: flex;
  position: relative;
}

.element-register .title-wrapper {
  background-color: var(--themesilver-sand);
  opacity: .4;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.element-register .title-5 {
  color: var(--themeswamp);
  letter-spacing: 1px;
  text-align: center;
  width: 64px;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 11.7px;
  font-weight: 700;
  line-height: 32px;
  position: relative;
}

.element-register .authentication-state-9 {
  background-color: var(--themewhite);
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  position: relative;
  box-shadow: 0 1px 8px #000e141a;
}

.element-register .title-6 {
  color: var(--themeswamp);
  letter-spacing: 1px;
  text-align: center;
  flex: 1;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 11.7px;
  font-weight: 700;
  line-height: 32px;
  position: relative;
}

.element-register .authentication-form-2 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding: 16px 0 0;
  display: flex;
  position: relative;
}

.element-register .authentication-form-3 {
  color: var(--themenevada);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 487px;
  height: 23px;
  margin-top: -1px;
  margin-left: -88.5px;
  margin-right: -88.5px;
  font-family: Inter, Helvetica;
  font-size: 16px;
  font-weight: 600;
  line-height: 22.9px;
  position: relative;
}

.element-register .authentication-form-wrapper {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.element-register .authentication-form-4 {
  background-color: var(--themewild-sand);
  border-radius: 4px 4px 0 0;
  align-items: center;
  width: 100%;
  height: 48px;
  padding: 0 16px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.element-register .authentication-form-5 {
  flex: 1;
  align-items: flex-start;
  gap: 1px;
  min-height: 30px;
  padding: 2px 0;
  display: flex;
  position: relative;
}

.element-register .authentication-form-6 {
  color: var(--themeblack-60);
  letter-spacing: .29px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.element-register .authentication-form-7 {
  box-sizing: border-box;
  background-color: #0000;
  border-color: #0000;
  width: 100%;
  height: auto;
  margin-top: 8px;
  padding: 8px;
  display: block;
  position: relative;
}

.element-register .authentication-form-8 {
  flex: 1;
  align-items: flex-start;
  gap: 1px;
  height: 30px;
  padding: 2px 0;
  display: flex;
  position: relative;
}

.element-register .authentication-4 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  display: flex;
  position: relative;
}

.element-register .authentication-form-9 {
  color: var(--themeoslo-gray);
  letter-spacing: .1px;
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 9.7px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.element-register .authentication-form-10 {
  background-color: var(--themeswamp);
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  min-height: 48px;
  padding: 8.5px 16px 9px;
  display: flex;
  position: relative;
}

.element-register .authentication-form-11 {
  color: var(--themewhite);
  letter-spacing: 1px;
  text-align: center;
  flex: 1;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 11.4px;
  font-weight: 700;
  line-height: 30px;
  position: relative;
}

.element-register .authentication-form-12 {
  border: 1px solid;
  border-color: var(--themeswamp);
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  min-height: 48px;
  padding: 8.5px 17px 9px;
  display: flex;
  position: relative;
}

.element-register .authentication-form-13 {
  color: var(--themeswamp);
  letter-spacing: 1px;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 11.7px;
  font-weight: 700;
  line-height: 30px;
  position: relative;
}

.element-register .footer-11 {
  background-color: #0000 !important;
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
}

.element-register .footer-12 {
  margin-left: -324px !important;
}

.element-register .frame-wrapper {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  padding: 60px 16px;
  display: flex;
  position: relative;
}

.element-register .frame-5 {
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  display: flex;
  position: relative;
}

.element-register .authentication-form-14 {
  background-color: var(--themewhite);
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 21px;
  max-width: 570px;
  padding: 20px 0;
  display: flex;
  position: relative;
}

.element-register .authentication-state-10 {
  flex-direction: column;
  align-items: flex-start;
  width: 32px;
  height: 2px;
  margin-left: -40px;
  margin-right: -8px;
  padding: 0 4px;
  display: flex;
  position: relative;
}

.element-register .authentication-state-11 {
  flex-direction: column;
  align-items: flex-start;
  width: 32px;
  height: 1px;
  margin-left: -8px;
  margin-right: -40px;
  padding: 0 4px;
  display: flex;
  position: relative;
}

.element-register .authentication-form-15 {
  color: var(--themenevada);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 487px;
  height: 23px;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 16px;
  font-weight: 600;
  line-height: 22.9px;
  position: relative;
}

.modal-overlay {
  z-index: 999;
  justify-content: center;
  align-items: center;
  width: 80vw;
  height: 80vh;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal-content {
  background-color: #fff;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  padding: 40px;
  overflow-y: auto;
}

.modal-title {
  margin-bottom: 20px;
  font-size: 1.5rem;
  font-weight: bold;
}

.modal-text {
  white-space: pre-wrap;
  font-size: 1rem;
  line-height: 1.5;
}

.close-button {
  color: #000;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  margin-top: 20px;
  padding: 10px 20px;
}

.element-sperren-desktop {
  background-color: #f5f5f5;
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
}

.element-sperren-desktop .instance-node-3 {
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
}

.element-sperren-desktop .navigation-desktop-mobile {
  margin-bottom: -891.42px !important;
  margin-left: -22697px !important;
  margin-right: unset !important;
}

.element-sperren-desktop .navigation-desktop-instance {
  margin-left: unset !important;
}

.element-sperren-desktop .navigation-desktop-mobile-instance {
  margin-bottom: -893.42px !important;
  margin-left: -22697px !important;
  margin-right: unset !important;
}

.element-sperren-desktop .navigation-desktop-2 {
  margin-bottom: -891.42px !important;
  margin-left: -22761px !important;
  margin-right: unset !important;
}

.element-sperren-desktop .navigation-desktop-3 {
  margin-bottom: -893.42px !important;
  margin-left: -22761px !important;
  margin-right: unset !important;
}

.element-sperren-desktop .navigation-desktop-4 {
  margin-bottom: -891.42px !important;
  margin-left: -22825px !important;
  margin-right: unset !important;
}

.element-sperren-desktop .navigation-desktop-5 {
  margin-bottom: -893.42px !important;
  margin-left: -22825px !important;
  margin-right: unset !important;
}

.element-sperren-desktop .page-control {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  padding: 0 32px;
  display: flex;
  position: relative;
}

.element-sperren-desktop .single-transfer {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  display: flex;
  position: relative;
}

.element-sperren-desktop .footer-4 {
  background-color: #0000 !important;
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
}

.element-sperren-desktop .footer-5 {
  margin-left: -324px !important;
}

.element-sperren-desktop .page-content-framer {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  display: flex;
  position: relative;
}

.element-sperren-desktop .page-control-2 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 20px;
  width: 900px;
  max-width: 1200px;
  padding: 0 32px;
  display: flex;
  position: relative;
}

.element-sperren-desktop .single-transfer-cell-instance {
  align-self: stretch !important;
  width: 100% !important;
}

.subtitle-2 {
  color: var(--themeswamp);
  font-family: var(--theme-inter-bold-15-75-upper-font-family);
  font-size: var(--theme-inter-bold-15-75-upper-font-size);
  font-style: var(--theme-inter-bold-15-75-upper-font-style);
  font-weight: var(--theme-inter-bold-15-75-upper-font-weight);
  letter-spacing: var(--theme-inter-bold-15-75-upper-letter-spacing);
  line-height: var(--theme-inter-bold-15-75-upper-line-height);
  align-self: stretch;
  position: relative;
}

.single-transfer-cell {
  background-color: var(--themewhite);
  border-color: #e9ebed;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  padding: 9.14px 16px 10.16px;
  display: flex;
  position: relative;
}

.single-transfer-cell .frame-2 {
  flex: none;
  align-items: center;
  position: relative;
}

.single-transfer-cell .left-container {
  flex: none;
  align-items: center;
  gap: 5px;
  display: inline-flex;
  position: relative;
}

.single-transfer-cell .image-4 {
  width: 35px;
  height: 35px;
  position: relative;
  overflow: hidden;
}

.single-transfer-cell .image-4 img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  display: block;
}

.single-transfer-cell .name-2 {
  color: var(--themeswamp);
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 13.9px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
}

.single-transfer-cell .text-wrapper-11 {
  color: #000e14;
  letter-spacing: 0;
  font-family: Inter, Helvetica;
  font-size: 13.9px;
  font-weight: 400;
  line-height: 24px;
}

.single-transfer-cell .text-wrapper-12 {
  font-weight: 700;
}

.single-transfer-cell .left-container-2 {
  flex: none;
  align-items: center;
  display: inline-flex;
  position: relative;
}

.single-transfer-cell .team-logo {
  justify-content: flex-end;
  align-items: center;
  width: 24px;
  display: flex;
  position: relative;
}

.single-transfer-cell .image-5 {
  background-image: url("image-4.6520545d.png");
  background-position: 50%;
  background-size: cover;
  flex: 1;
  height: 24px;
  position: relative;
}

.single-transfer-cell .team-name {
  flex: none;
  justify-content: flex-end;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.single-transfer-cell .team-name-2 {
  color: var(--themeswamp);
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 13.7px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
}

.single-transfer-cell .left-container-3 {
  flex: none;
  align-items: center;
  gap: 10px;
  max-width: 369.33px;
  display: inline-flex;
  position: relative;
}

.single-transfer-cell .name-3 {
  color: var(--themeswamp);
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 13.9px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
}

.single-transfer-cell.property-1-desktop {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 25px;
  width: 100%;
  max-width: 1200px;
  height: 44px;
}

.single-transfer-cell.property-1-mobile {
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
}

.single-transfer-cell.property-1-desktop .frame-2 {
  gap: 25px;
  margin-top: -5.15px;
  margin-bottom: -5.15px;
  display: inline-flex;
}

.single-transfer-cell.property-1-mobile .frame-2 {
  flex-wrap: wrap;
  align-self: stretch;
  gap: 10px;
  width: 100%;
  display: flex;
}

.single-transfer-cell.property-1-desktop .left-container-2 {
  gap: 10px;
  max-width: 369.33px;
}

.single-transfer-cell.property-1-mobile .left-container-2 {
  gap: 25px;
}

.team-image {
  width: 100%;
}

.element-table-mobile {
  background-color: #f5f5f5;
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
}

.element-table-mobile .instance-node-5 {
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
}

.element-table-mobile .navigation-desktop-6 {
  margin-bottom: -906.42px !important;
  margin-left: -617px !important;
  margin-right: unset !important;
}

.element-table-mobile .navigation-desktop-7 {
  margin-left: unset !important;
}

.element-table-mobile .navigation-desktop-8 {
  margin-bottom: -908.42px !important;
  margin-left: -617px !important;
  margin-right: unset !important;
}

.element-table-mobile .navigation-desktop-9 {
  margin-bottom: -906.42px !important;
  margin-left: -681px !important;
  margin-right: unset !important;
}

.element-table-mobile .navigation-desktop-10 {
  margin-bottom: -908.42px !important;
  margin-left: -681px !important;
  margin-right: unset !important;
}

.element-table-mobile .navigation-desktop-11 {
  margin-bottom: -906.42px !important;
  margin-left: -745px !important;
  margin-right: unset !important;
}

.element-table-mobile .navigation-desktop-12 {
  margin-bottom: -908.42px !important;
  margin-left: -745px !important;
  margin-right: unset !important;
}

.element-table-mobile .league-table {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.element-table-mobile .league-table-wrapper {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 0 5px;
  display: flex;
  position: relative;
}

.element-table-mobile .league-table-2 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  max-width: 1140px;
  display: flex;
  position: relative;
}

.element-table-mobile .container-2 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.element-table-mobile .div-wrapper-2 {
  background-color: var(--themewhite);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.element-table-mobile .table {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.element-table-mobile .row-wrapper {
  background-color: var(--themeheavy-metal);
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  height: 21px;
  display: flex;
  position: relative;
}

.element-table-mobile .row {
  justify-content: flex-end;
  align-self: stretch;
  align-items: center;
  width: 100%;
  height: 21px;
  padding: 0;
  display: flex;
  position: relative;
}

.element-table-mobile .cell {
  flex-direction: column;
  align-items: flex-start;
  width: 23.66px;
  height: 21px;
  padding: 0 12px 1px 1px;
  display: flex;
  position: relative;
}

.element-table-mobile .text-wrapper-25 {
  color: var(--themegallery);
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  margin-right: -.34px;
  font-family: Inter, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  position: relative;
}

.element-table-mobile .cell-2 {
  flex-direction: column;
  align-items: flex-start;
  width: 20.83px;
  height: 21px;
  padding: 0 8px 1px 1px;
  display: flex;
  position: relative;
}

.element-table-mobile .text-wrapper-26 {
  color: var(--themegallery);
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  margin-right: -.17px;
  font-family: Inter, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  position: relative;
}

.element-table-mobile .cell-3 {
  flex-direction: column;
  align-items: flex-start;
  width: 17.53px;
  height: 21px;
  padding: 0 8px 1px 1px;
  display: flex;
  position: relative;
}

.element-table-mobile .text-wrapper-27 {
  color: var(--themegallery);
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  margin-right: -.47px;
  font-family: Inter, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  position: relative;
}

.element-table-mobile .cell-4 {
  flex-direction: column;
  align-items: flex-start;
  width: 20.45px;
  height: 21px;
  padding: 0 11.99px 1px 1.94px;
  display: flex;
  position: relative;
}

.element-table-mobile .text-wrapper-28 {
  color: var(--themegallery);
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  margin-right: -.48px;
  font-family: Inter, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  position: relative;
}

.element-table-mobile .cell-5 {
  flex-direction: column;
  align-items: flex-start;
  width: 45.84px;
  height: 21px;
  padding: 0 24.42px 1px 13.42px;
  display: flex;
  position: relative;
}

.element-table-mobile .text-wrapper-29 {
  color: var(--themegallery);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  margin-right: -1px;
  font-family: Inter, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  position: relative;
}

.element-table-mobile .cell-6 {
  flex-direction: column;
  align-items: flex-start;
  width: 31.89px;
  height: 21px;
  padding: 0 16px 1px 8.44px;
  display: flex;
  position: relative;
}

.element-table-mobile .text-wrapper-30 {
  color: var(--themegallery);
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  margin-right: -.55px;
  font-family: Inter, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  position: relative;
}

.element-table-mobile .body {
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.element-table-mobile .row-2 {
  justify-content: space-around;
  align-self: stretch;
  align-items: center;
  width: 100%;
  height: 40px;
  display: flex;
  position: relative;
}

.element-table-mobile .data {
  background-color: var(--themela-palma);
  width: 3px;
  height: 40px;
  position: relative;
}

.element-table-mobile .data-2 {
  flex-direction: column;
  align-items: flex-start;
  width: 30.89px;
  height: 40px;
  padding: 12px;
  display: flex;
  position: relative;
}

.element-table-mobile .text-wrapper-31 {
  color: var(--themeouter-space);
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: 17.1px;
  position: relative;
}

.element-table-mobile .data-3 {
  object-fit: contain;
  width: 20px;
  height: 20px;
  position: relative;
}

.element-table-mobile .container-wrapper {
  flex-direction: column;
  align-items: flex-start;
  width: 140px;
  height: 40px;
  padding: 12px;
  display: flex;
  position: relative;
}

.element-table-mobile .container-3 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  margin-bottom: -.86px;
  display: flex;
  position: relative;
}

.element-table-mobile .text-wrapper-32 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  align-self: stretch;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: 17.1px;
  position: relative;
  overflow: hidden;
}

.element-table-mobile .data-4 {
  flex-direction: column;
  align-items: flex-start;
  width: 46.66px;
  height: 40px;
  padding: 13.85px 12.01px 9.01px 27.2px;
  display: flex;
  position: relative;
}

.element-table-mobile .text-wrapper-33 {
  color: var(--themeouter-space);
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  margin-right: -.55px;
  font-family: Inter, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: 17.1px;
  position: relative;
}

.element-table-mobile .data-5 {
  flex-direction: column;
  align-items: flex-start;
  width: 20.83px;
  height: 40px;
  padding: 13.85px 8px 9.01px 5.38px;
  display: flex;
  position: relative;
}

.element-table-mobile .text-wrapper-34 {
  color: var(--themeoslo-gray);
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  margin-right: -.55px;
  font-family: Inter, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: 17.1px;
  position: relative;
}

.element-table-mobile .data-6 {
  flex-direction: column;
  align-items: flex-start;
  width: 17.53px;
  height: 40px;
  padding: 13.85px 8px 9.01px 2.08px;
  display: flex;
  position: relative;
}

.element-table-mobile .data-7 {
  flex-direction: column;
  align-items: flex-start;
  width: 20.45px;
  height: 40px;
  padding: 13.85px 12px 9.01px 1px;
  display: flex;
  position: relative;
}

.element-table-mobile .data-8 {
  flex-direction: column;
  align-items: flex-start;
  width: 45.84px;
  height: 40px;
  padding: 13.85px 15.72px 9.01px 4.71px;
  display: flex;
  position: relative;
}

.element-table-mobile .text-wrapper-35 {
  color: var(--themeoslo-gray);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: 17.1px;
  position: relative;
}

.element-table-mobile .data-9 {
  flex-direction: column;
  align-items: flex-start;
  width: 31.89px;
  height: 40px;
  padding: 13.85px 16px 9.01px 1px;
  display: flex;
  position: relative;
}

.element-table-mobile .text-wrapper-36 {
  color: var(--themeswamp);
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: 17.1px;
  position: relative;
}

.element-table-mobile .mask-group-wrapper {
  flex-direction: column;
  align-items: flex-start;
  width: 21px;
  height: 40px;
  padding: 10.92px 4px 13.08px 1px;
  display: flex;
  position: relative;
}

.element-table-mobile .mask-group {
  width: 20px;
  height: 20px;
  position: relative;
}

.element-table-mobile .data-10 {
  object-fit: cover;
  width: 44px;
  height: 40px;
  position: relative;
}

.element-table-mobile .text-wrapper-37 {
  color: var(--themeoslo-gray);
  letter-spacing: 0;
  align-self: stretch;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: 17.1px;
  position: relative;
}

.element-table-mobile .data-11 {
  flex-direction: column;
  align-items: flex-start;
  width: 45.84px;
  height: 40px;
  padding: 13.85px 19.46px 9.01px 8.43px;
  display: flex;
  position: relative;
}

.element-table-mobile .text-wrapper-38 {
  color: var(--themeoslo-gray);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  margin-right: -1.05px;
  font-family: Inter, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: 17.1px;
  position: relative;
}

.element-table-mobile .img-wrapper {
  flex-direction: column;
  align-items: flex-start;
  width: 21px;
  height: 40px;
  padding: 14.42px 4px 9.58px 1px;
  display: flex;
  position: relative;
}

.element-table-mobile .text-wrapper-39 {
  color: var(--themeoslo-gray);
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: 17.1px;
  position: relative;
}

.element-table-mobile .text-wrapper-40 {
  color: var(--themeoslo-gray);
  letter-spacing: 0;
  align-self: stretch;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 10.7px;
  font-weight: 400;
  line-height: 17.1px;
  position: relative;
}

.element-table-mobile .text-wrapper-41 {
  color: var(--themeoslo-gray);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  margin-right: -.05px;
  font-family: Inter, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: 17.1px;
  position: relative;
}

.element-table-mobile .data-12 {
  background-color: var(--themescooter);
  width: 3px;
  height: 40px;
  position: relative;
}

.element-table-mobile .data-13 {
  flex-direction: column;
  align-items: flex-start;
  width: 45.84px;
  height: 40px;
  padding: 13.85px 12px 9.01px 1px;
  display: flex;
  position: relative;
}

.element-table-mobile .data-14 {
  background-color: var(--themecornflower);
  width: 3px;
  height: 40px;
  position: relative;
}

.element-table-mobile .text-wrapper-42 {
  color: var(--themeoslo-gray);
  letter-spacing: 0;
  align-self: stretch;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 10.9px;
  font-weight: 400;
  line-height: 17.1px;
  position: relative;
}

.element-table-mobile .text-wrapper-43 {
  color: var(--themeoslo-gray);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  margin-right: -.05px;
  font-family: Inter, Helvetica;
  font-size: 11.6px;
  font-weight: 400;
  line-height: 17.1px;
  position: relative;
}

.element-table-mobile .row-3 {
  justify-content: center;
  align-self: stretch;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 0 0 0 3px;
  display: flex;
  position: relative;
}

.element-table-mobile .data-15 {
  flex-direction: column;
  align-items: flex-start;
  width: 31.89px;
  height: 40px;
  padding: 13.85px 16px 9.01px 8.44px;
  display: flex;
  position: relative;
}

.element-table-mobile .text-wrapper-44 {
  color: var(--themeswamp);
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  margin-right: -.55px;
  font-family: Inter, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: 17.1px;
  position: relative;
}

.element-table-mobile .data-16 {
  flex-direction: column;
  align-items: flex-start;
  width: 30.89px;
  height: 40px;
  padding: 13.85px 4px 9.01px 12px;
  display: flex;
  position: relative;
}

.element-table-mobile .text-wrapper-45 {
  color: var(--themeoslo-gray);
  letter-spacing: 0;
  align-self: stretch;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 11.8px;
  font-weight: 400;
  line-height: 17.1px;
  position: relative;
}

.element-table-mobile .text-wrapper-46 {
  color: var(--themeoslo-gray);
  letter-spacing: 0;
  align-self: stretch;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 11.6px;
  font-weight: 400;
  line-height: 17.1px;
  position: relative;
}

.element-table-mobile .data-17 {
  background-color: #eeac57;
  width: 3px;
  height: 40px;
  position: relative;
}

.element-table-mobile .data-18 {
  background-color: #d75452;
  width: 3px;
  height: 40px;
  position: relative;
}

.element-table-mobile .text-wrapper-47 {
  color: var(--themeoslo-gray);
  letter-spacing: 0;
  align-self: stretch;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 11.4px;
  font-weight: 400;
  line-height: 17.1px;
  position: relative;
}

.element-table-mobile .text-wrapper-48 {
  color: var(--themeoslo-gray);
  letter-spacing: 0;
  align-self: stretch;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 11.2px;
  font-weight: 400;
  line-height: 17.1px;
  position: relative;
}

.element-table-mobile .table-legend {
  background-color: var(--themewhite);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
  padding: 24px 24px 40px;
  display: flex;
  position: relative;
}

.element-table-mobile .list-2 {
  align-self: stretch;
  width: 100%;
  height: 120.56px;
  position: relative;
}

.element-table-mobile .legend-wrapper {
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 0;
  width: 332px;
  height: 21px;
  display: flex;
  position: absolute;
  top: -1px;
  left: 0;
}

.element-table-mobile .legend {
  color: var(--themeouter-space);
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 9.9px;
  font-weight: 700;
  line-height: 17.1px;
  position: relative;
}

.element-table-mobile .overlap {
  width: 332px;
  height: 19px;
  position: absolute;
  top: 27px;
  left: 0;
}

.element-table-mobile .item-4 {
  flex-direction: column;
  align-items: flex-start;
  width: 177px;
  padding: 0 0 .57px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.element-table-mobile .m-matches {
  color: #0000;
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 9.8px;
  font-weight: 400;
  line-height: 17.1px;
  position: relative;
}

.element-table-mobile .text-wrapper-49 {
  color: #34383c;
}

.element-table-mobile .text-wrapper-50 {
  color: #000e14;
}

.element-table-mobile .item-5 {
  flex-direction: column;
  align-items: flex-start;
  width: 177px;
  padding: 0 0 .57px;
  display: flex;
  position: absolute;
  top: 0;
  left: 155px;
}

.element-table-mobile .goal-difference {
  color: #0000;
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 10.3px;
  font-weight: 400;
  line-height: 17.1px;
  position: relative;
}

.element-table-mobile .overlap-group {
  width: 332px;
  height: 19px;
  position: absolute;
  top: 53px;
  left: 0;
}

.element-table-mobile .w-won {
  color: #0000;
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 9.6px;
  font-weight: 400;
  line-height: 17.1px;
  position: relative;
}

.element-table-mobile .p-points {
  color: #0000;
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 9.4px;
  font-weight: 400;
  line-height: 17.1px;
  position: relative;
}

.element-table-mobile .overlap-group-2 {
  width: 332px;
  height: 19px;
  position: absolute;
  top: 78px;
  left: 0;
}

.element-table-mobile .d-draw {
  color: #0000;
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 9.2px;
  font-weight: 400;
  line-height: 17.1px;
  position: relative;
}

.element-table-mobile .g-goals {
  color: #0000;
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 9px;
  font-weight: 400;
  line-height: 17.1px;
  position: relative;
}

.element-table-mobile .l-lost-wrapper {
  flex-direction: column;
  align-items: flex-start;
  width: 177px;
  padding: 0 0 .57px;
  display: flex;
  position: absolute;
  top: 103px;
  left: 0;
}

.element-table-mobile .l-lost {
  color: #0000;
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 8.6px;
  font-weight: 400;
  line-height: 17.1px;
  position: relative;
}

.element-table-mobile .footer-7 {
  background-color: #0000 !important;
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
}

.element-table-mobile .footer-8 {
  margin-left: -324px !important;
}

.element-table-mobile .content-frame {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  display: flex;
  position: relative;
}

.element-table-mobile .page-content-wrapper {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  max-width: 1140px;
  padding: 0 32px;
  display: flex;
  position: relative;
}

.element-table-mobile .page-content-5 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  padding: 60px 0;
  display: flex;
  position: relative;
}

.element-table-mobile .league-table-content {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  max-width: 1140px;
  display: flex;
  position: relative;
}

.element-table-mobile .row-4 {
  justify-content: flex-end;
  align-self: stretch;
  align-items: flex-end;
  width: 100%;
  height: 21px;
  display: flex;
  position: relative;
}

.element-table-mobile .cell-7 {
  flex-direction: column;
  align-items: flex-start;
  width: 79.3px;
  height: 21px;
  padding: 0 32px 1px 1px;
  display: flex;
  position: relative;
}

.element-table-mobile .text-wrapper-51 {
  color: var(--themegallery);
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  margin-right: -.7px;
  font-family: Inter, Helvetica;
  font-size: 11.4px;
  font-weight: 400;
  line-height: 20px;
  position: relative;
}

.element-table-mobile .cell-8 {
  flex-direction: column;
  align-items: flex-start;
  width: 28.83px;
  height: 21px;
  padding: 0 16px 1px 1px;
  display: flex;
  position: relative;
}

.element-table-mobile .cell-9 {
  flex-direction: column;
  align-items: flex-start;
  width: 26.92px;
  height: 21px;
  padding: 0 16px 1px 2.39px;
  display: flex;
  position: relative;
}

.element-table-mobile .cell-10 {
  flex-direction: column;
  align-items: flex-start;
  width: 42.92px;
  height: 21px;
  padding: 0 31.99px 1px 4.41px;
  display: flex;
  position: relative;
}

.element-table-mobile .cell-11 {
  flex-direction: column;
  align-items: flex-start;
  width: 60.78px;
  height: 21px;
  padding: 0 22.94px 1px 7.93px;
  display: flex;
  position: relative;
}

.element-table-mobile .text-wrapper-52 {
  color: var(--themegallery);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  margin-right: -.09px;
  font-family: Inter, Helvetica;
  font-size: 11.2px;
  font-weight: 400;
  line-height: 20px;
  position: relative;
}

.element-table-mobile .cell-12 {
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  width: 62.77px;
  height: 21px;
  padding: 0 32px 1px 12.86px;
  display: flex;
  position: relative;
}

.element-table-mobile .text-wrapper-53 {
  color: var(--themegallery);
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  margin-right: -.09px;
  font-family: Inter, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  position: relative;
}

.element-table-mobile .cell-13 {
  flex-direction: column;
  align-items: flex-start;
  width: 51.33px;
  height: 21px;
  padding: 0 16px 1px 1px;
  display: flex;
  position: relative;
}

.element-table-mobile .text-wrapper-54 {
  color: var(--themegallery);
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 11.6px;
  font-weight: 400;
  line-height: 20px;
  position: relative;
}

.element-table-mobile .league-table-cells {
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.element-table-mobile .row-5 {
  justify-content: center;
  align-self: stretch;
  align-items: center;
  width: 100%;
  height: 56px;
  display: flex;
  position: relative;
}

.element-table-mobile .data-19 {
  background-color: var(--themela-palma);
  width: 3px;
  height: 56px;
  position: relative;
}

.element-table-mobile .data-20 {
  flex-direction: column;
  align-items: flex-start;
  width: 35.84px;
  height: 56px;
  padding: 19.15px 4px 13.99px 21.92px;
  display: flex;
  position: relative;
}

.element-table-mobile .text-wrapper-55 {
  color: var(--themeouter-space);
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: 22.9px;
  position: relative;
}

.element-table-mobile .data-21 {
  object-fit: cover;
  width: 65px;
  height: 56px;
  position: relative;
}

.element-table-mobile .data-22 {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  height: 56px;
  padding: 13.58px 1px;
  display: flex;
  position: relative;
}

.element-table-mobile .container-4 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  margin-bottom: -.14px;
  padding: 5.57px 0 .41px;
  display: flex;
  position: relative;
}

.element-table-mobile .text-wrapper-56 {
  color: #000;
  letter-spacing: 0;
  align-self: stretch;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 15.2px;
  font-weight: 700;
  line-height: 22.9px;
  position: relative;
}

.element-table-mobile .data-23 {
  flex-direction: column;
  align-items: flex-start;
  width: 104.31px;
  height: 56px;
  padding: 19.15px 32.01px 13.99px 62.38px;
  display: flex;
  position: relative;
}

.element-table-mobile .text-wrapper-57 {
  color: var(--themeouter-space);
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  margin-right: -1.08px;
  font-family: Inter, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: 22.9px;
  position: relative;
}

.element-table-mobile .data-24 {
  flex-direction: column;
  align-items: flex-start;
  width: 28.83px;
  height: 56px;
  padding: 19.15px 16px 13.99px 2.91px;
  display: flex;
  position: relative;
}

.element-table-mobile .text-wrapper-58 {
  color: var(--themeoslo-gray);
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  margin-right: -1.08px;
  font-family: Inter, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: 22.9px;
  position: relative;
}

.element-table-mobile .data-25 {
  flex-direction: column;
  align-items: flex-start;
  width: 26.92px;
  height: 56px;
  padding: 19.15px 16px 13.99px 1px;
  display: flex;
  position: relative;
}

.element-table-mobile .data-26 {
  flex-direction: column;
  align-items: flex-start;
  width: 42.92px;
  height: 56px;
  padding: 19.15px 32px 13.99px 1px;
  display: flex;
  position: relative;
}

.element-table-mobile .text-wrapper-59 {
  color: var(--themeoslo-gray);
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  margin-right: -2.08px;
  font-family: Inter, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: 22.9px;
  position: relative;
}

.element-table-mobile .data-27 {
  flex-direction: column;
  align-items: flex-start;
  width: 60.78px;
  height: 56px;
  padding: 19.15px 20.97px 13.99px 5.95px;
  display: flex;
  position: relative;
}

.element-table-mobile .text-wrapper-60 {
  color: var(--themeoslo-gray);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  margin-right: -2.14px;
  font-family: Inter, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: 22.9px;
  position: relative;
}

.element-table-mobile .data-28 {
  flex-direction: column;
  align-items: flex-start;
  width: 62.77px;
  height: 56px;
  padding: 19.15px 32px 13.99px 1px;
  display: flex;
  position: relative;
}

.element-table-mobile .text-wrapper-61 {
  color: var(--thememountain-meadow);
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  margin-right: -.23px;
  font-family: Inter, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: 22.9px;
  position: relative;
}

.element-table-mobile .data-29 {
  flex-direction: column;
  align-items: flex-start;
  width: 51.33px;
  height: 56px;
  padding: 19.15px 16px 13.99px 15.49px;
  display: flex;
  position: relative;
}

.element-table-mobile .text-wrapper-62 {
  color: var(--themeswamp);
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: 22.9px;
  position: relative;
}

.element-table-mobile .data-30 {
  flex-direction: column;
  align-items: center;
  width: 40px;
  height: 56px;
  padding: 20px 15px;
  display: flex;
  position: relative;
}

.element-table-mobile .data-31 {
  object-fit: cover;
  width: 44px;
  height: 56px;
  position: relative;
}

.element-table-mobile .text-wrapper-63 {
  color: var(--themeoslo-gray);
  letter-spacing: 0;
  align-self: stretch;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 15.5px;
  font-weight: 700;
  line-height: 22.9px;
  position: relative;
}

.element-table-mobile .data-32 {
  flex-direction: column;
  align-items: flex-start;
  width: 60.78px;
  height: 56px;
  padding: 19.15px 25.92px 13.99px 10.92px;
  display: flex;
  position: relative;
}

.element-table-mobile .text-wrapper-64 {
  color: var(--themeoslo-gray);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  margin-right: -2.06px;
  font-family: Inter, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: 22.9px;
  position: relative;
}

.element-table-mobile .data-33 {
  flex-direction: column;
  align-items: flex-start;
  width: 62.77px;
  height: 56px;
  padding: 19.15px 32.01px 13.99px 10.92px;
  display: flex;
  position: relative;
}

.element-table-mobile .text-wrapper-65 {
  color: var(--thememountain-meadow);
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  margin-right: -.16px;
  font-family: Inter, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: 22.9px;
  position: relative;
}

.element-table-mobile .row-6 {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.element-table-mobile .data-34 {
  flex-direction: column;
  align-items: flex-start;
  width: 21px;
  height: 56px;
  padding: 23.58px 4px 16.42px 1px;
  display: flex;
  position: relative;
}

.element-table-mobile .text-wrapper-66 {
  color: var(--themeoslo-gray);
  letter-spacing: 0;
  align-self: stretch;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 15.4px;
  font-weight: 700;
  line-height: 22.9px;
  position: relative;
}

.element-table-mobile .text-wrapper-67 {
  color: var(--themeoslo-gray);
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: 22.9px;
  position: relative;
}

.element-table-mobile .text-wrapper-68 {
  color: var(--themeoslo-gray);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  margin-right: -.14px;
  font-family: Inter, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: 22.9px;
  position: relative;
}

.element-table-mobile .text-wrapper-69 {
  color: var(--thememountain-meadow);
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  margin-right: -1.16px;
  font-family: Inter, Helvetica;
  font-size: 15.8px;
  font-weight: 700;
  line-height: 22.9px;
  position: relative;
}

.element-table-mobile .text-wrapper-70 {
  color: var(--themeoslo-gray);
  letter-spacing: 0;
  align-self: stretch;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 15px;
  font-weight: 700;
  line-height: 22.9px;
  position: relative;
}

.element-table-mobile .text-wrapper-71 {
  color: var(--themeoslo-gray);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  margin-right: -1.06px;
  font-family: Inter, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: 22.9px;
  position: relative;
}

.element-table-mobile .text-wrapper-72 {
  color: var(--thememountain-meadow);
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  margin-right: -1.16px;
  font-family: Inter, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: 22.9px;
  position: relative;
}

.element-table-mobile .data-35 {
  background-color: var(--themescooter);
  width: 3px;
  height: 56px;
  position: relative;
}

.element-table-mobile .data-36 {
  flex-direction: column;
  align-items: flex-start;
  width: 60.78px;
  height: 56px;
  padding: 19.15px 16px 13.99px 1px;
  display: flex;
  position: relative;
}

.element-table-mobile .text-wrapper-73 {
  color: var(--themeoslo-gray);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: 22.9px;
  position: relative;
}

.element-table-mobile .text-wrapper-74 {
  color: var(--thememountain-meadow);
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  margin-right: -1.16px;
  font-family: Inter, Helvetica;
  font-size: 15.4px;
  font-weight: 700;
  line-height: 22.9px;
  position: relative;
}

.element-table-mobile .data-37 {
  background-color: var(--themecornflower);
  width: 3px;
  height: 56px;
  position: relative;
}

.element-table-mobile .text-wrapper-75 {
  color: var(--themeoslo-gray);
  letter-spacing: 0;
  align-self: stretch;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 15.6px;
  font-weight: 700;
  line-height: 22.9px;
  position: relative;
}

.element-table-mobile .text-wrapper-76 {
  color: var(--themeoslo-gray);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  margin-right: -2.06px;
  font-family: Inter, Helvetica;
  font-size: 15.6px;
  font-weight: 700;
  line-height: 22.9px;
  position: relative;
}

.element-table-mobile .row-7 {
  justify-content: center;
  align-self: stretch;
  align-items: center;
  width: 100%;
  height: 56px;
  padding: 0 0 0 3px;
  display: flex;
  position: relative;
}

.element-table-mobile .text-wrapper-77 {
  color: var(--themeouter-space);
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  margin-right: -.08px;
  font-family: Inter, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: 22.9px;
  position: relative;
}

.element-table-mobile .text-wrapper-78 {
  color: var(--thememountain-meadow);
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: 22.9px;
  position: relative;
}

.element-table-mobile .text-wrapper-79 {
  color: var(--themeoslo-gray);
  letter-spacing: 0;
  align-self: stretch;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 15.9px;
  font-weight: 700;
  line-height: 22.9px;
  position: relative;
}

.element-table-mobile .text-wrapper-80 {
  color: var(--themeoslo-gray);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  margin-right: -1.14px;
  font-family: Inter, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: 22.9px;
  position: relative;
}

.element-table-mobile .data-38 {
  flex-direction: column;
  align-items: flex-start;
  width: 51.33px;
  height: 56px;
  padding: 19.15px 16px 13.99px 25.41px;
  display: flex;
  position: relative;
}

.element-table-mobile .text-wrapper-81 {
  color: var(--themeswamp);
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  margin-right: -1.08px;
  font-family: Inter, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: 22.9px;
  position: relative;
}

.element-table-mobile .data-39 {
  flex-direction: column;
  align-items: flex-start;
  width: 35.84px;
  height: 56px;
  padding: 19.15px 4px 13.99px 12px;
  display: flex;
  position: relative;
}

.element-table-mobile .data-40 {
  flex-direction: column;
  align-items: flex-start;
  width: 62.77px;
  height: 56px;
  padding: 19.15px 32.01px 13.99px 13.87px;
  display: flex;
  position: relative;
}

.element-table-mobile .text-wrapper-82 {
  color: var(--themepomegranate);
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  margin-right: -1.11px;
  font-family: Inter, Helvetica;
  font-size: 15.5px;
  font-weight: 700;
  line-height: 22.9px;
  position: relative;
}

.element-table-mobile .text-wrapper-83 {
  color: var(--themeswamp);
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  margin-right: -.08px;
  font-family: Inter, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: 22.9px;
  position: relative;
}

.element-table-mobile .text-wrapper-84 {
  color: var(--themeoslo-gray);
  letter-spacing: 0;
  align-self: stretch;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 15.1px;
  font-weight: 700;
  line-height: 22.9px;
  position: relative;
}

.element-table-mobile .text-wrapper-85 {
  color: var(--themepomegranate);
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  margin-right: -1.11px;
  font-family: Inter, Helvetica;
  font-size: 15.8px;
  font-weight: 700;
  line-height: 22.9px;
  position: relative;
}

.element-table-mobile .text-wrapper-86 {
  color: var(--themeoslo-gray);
  letter-spacing: 0;
  align-self: stretch;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 14.9px;
  font-weight: 700;
  line-height: 22.9px;
  position: relative;
}

.element-table-mobile .text-wrapper-87 {
  color: var(--themepomegranate);
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  margin-right: -1.11px;
  font-family: Inter, Helvetica;
  font-size: 15.9px;
  font-weight: 700;
  line-height: 22.9px;
  position: relative;
}

.element-table-mobile .data-41 {
  background-color: #eeac57;
  width: 3px;
  height: 56px;
  position: relative;
}

.element-table-mobile .data-42 {
  background-color: #d75452;
  width: 3px;
  height: 56px;
  position: relative;
}

.element-table-mobile .data-43 {
  flex-direction: column;
  align-items: flex-start;
  width: 62.77px;
  height: 56px;
  padding: 19.15px 32.01px 13.99px 3.95px;
  display: flex;
  position: relative;
}

.element-table-mobile .text-wrapper-88 {
  color: var(--themepomegranate);
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  margin-right: -.19px;
  font-family: Inter, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: 22.9px;
  position: relative;
}

.element-table-mobile .list-margin-wrapper {
  background-color: var(--themewhite);
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  padding: 24px;
  display: flex;
  position: relative;
}

.element-table-mobile .list-margin {
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  width: 502px;
  padding: 0 0 16px;
  display: flex;
  position: relative;
}

.element-table-mobile .list-3 {
  align-self: stretch;
  width: 100%;
  height: 135.56px;
  position: relative;
}

.element-table-mobile .item-6 {
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 0;
  width: 502px;
  height: 21px;
  display: flex;
  position: absolute;
  top: 6px;
  left: 0;
}

.element-table-mobile .m-matches-wrapper {
  flex-direction: column;
  align-items: flex-start;
  width: 251px;
  padding: 0 0 .57px;
  display: flex;
  position: absolute;
  top: 42px;
  left: 0;
}

.element-table-mobile .w-won-wrapper {
  flex-direction: column;
  align-items: flex-start;
  width: 251px;
  padding: 0 0 .57px;
  display: flex;
  position: absolute;
  top: 68px;
  left: 0;
}

.element-table-mobile .d-draw-wrapper {
  flex-direction: column;
  align-items: flex-start;
  width: 251px;
  padding: 0 0 .57px;
  display: flex;
  position: absolute;
  top: 93px;
  left: 0;
}

.element-table-mobile .item-7 {
  flex-direction: column;
  align-items: flex-start;
  width: 251px;
  padding: 0 0 .57px;
  display: flex;
  position: absolute;
  top: 118px;
  left: 0;
}

.element-table-mobile .goal-difference-wrapper {
  flex-direction: column;
  align-items: flex-start;
  width: 251px;
  padding: 0 0 .57px;
  display: flex;
  position: absolute;
  top: 42px;
  left: 251px;
}

.element-table-mobile .p-points-wrapper {
  flex-direction: column;
  align-items: flex-start;
  width: 251px;
  padding: 0 0 .57px;
  display: flex;
  position: absolute;
  top: 68px;
  left: 251px;
}

.element-table-mobile .g-goals-wrapper {
  flex-direction: column;
  align-items: flex-start;
  width: 251px;
  padding: 0 0 .57px;
  display: flex;
  position: absolute;
  top: 93px;
  left: 251px;
}

.team-detail-squad {
  flex-direction: column;
  align-items: flex-start;
  padding: 4px;
  display: flex;
  position: relative;
}

.team-detail-squad .team-detail-squad-wrapper-2 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.team-detail-squad .div-10 {
  background-color: var(--themewhite);
  flex: 1;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 8px 16.01px 8px 16px;
  display: flex;
  position: relative;
  box-shadow: 0 2px 8px #000e140d;
}

.team-detail-squad .image-11 {
  object-fit: contain;
  background-position: 50%;
  background-size: cover;
  position: relative;
}

.team-detail-squad .team-detail-squad-wrapper-3 {
  flex-direction: column;
  align-items: flex-start;
  width: 46px;
  padding: 0 0 0 16px;
  display: flex;
  position: relative;
}

.team-detail-squad .text-wrapper-21 {
  color: var(--themeoslo-gray);
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
}

.team-detail-squad .team-detail-squad-wrapper-4 {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  padding: 0 0 0 10px;
  display: flex;
  position: relative;
}

.team-detail-squad .div-11 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.team-detail-squad .text-wrapper-22 {
  color: var(--themeouter-space);
  font-family: var(--theme-inter-regular-15-63-font-family);
  font-size: var(--theme-inter-regular-15-63-font-size);
  font-style: var(--theme-inter-regular-15-63-font-style);
  letter-spacing: var(--theme-inter-regular-15-63-letter-spacing);
  font-weight: bolder;
  line-height: var(--theme-inter-regular-15-63-line-height);
  white-space: nowrap;
  width: fit-content;
  max-width: 200px;
  font-family: var(--theme-inter-regular-15-63-font-family);
  font-size: 14px;
  font-style: var(--theme-inter-regular-15-63-font-style);
  font-weight: var(--theme-inter-regular-15-63-font-weight);
  letter-spacing: var(--theme-inter-regular-15-63-letter-spacing);
  line-height: var(--theme-inter-regular-15-63-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.team-detail-squad .text-wrapper-23 {
  color: var(--themeouter-space);
  font-family: var(--theme-inter-bold-15-63-font-family);
  font-size: var(--theme-inter-bold-15-63-font-size);
  font-style: var(--theme-inter-bold-15-63-font-style);
  font-weight: var(--theme-inter-bold-15-63-font-weight);
  letter-spacing: var(--theme-inter-bold-15-63-letter-spacing);
  line-height: var(--theme-inter-bold-15-63-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
  overflow: hidden;
}

.team-detail-squad .nationality-flags {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  padding: 2.55px 0 4px;
  display: inline-flex;
  position: relative;
}

.team-detail-squad .nationality-flags-2 {
  flex: none;
  align-items: center;
  display: inline-flex;
  position: relative;
}

.team-detail-squad .flagicon {
  border: 1px solid;
  border-color: var(--themegallery);
  object-fit: contain;
  background-position: 50%;
  background-size: cover;
  width: 20.66px;
  height: 16px;
  position: relative;
}

.team-detail-squad .flagicon-no {
  object-fit: contain;
  background-position: 50%;
  background-size: cover;
  width: 20.66px;
  height: 16px;
  position: relative;
}

.team-detail-squad .flagicon-2 {
  border: 1px solid;
  border-color: var(--themegallery);
  width: 20.66px;
  height: 16px;
  margin-left: --32e-15px;
  background-image: url("flagicon-2.46f9c028.png");
  background-position: 50%;
  background-size: cover;
  position: relative;
}

.team-detail-squad .no-image {
  align-self: stretch;
  width: 100%;
}

.team-detail-squad .image {
  width: 352px;
}

.popover {
  color: #fff;
  white-space: nowrap;
  z-index: 1000;
  pointer-events: none;
  background-color: #333;
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 12px;
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 4px 6px #0000001a;
}

.team-header {
  width: 100%;
}

.team-header .team-detail {
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.team-header .team-detail-header {
  background-color: var(--themetorch-red);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.team-header .team-detail-header-wrapper {
  background-color: #34383c;
  flex-wrap: wrap;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 0;
  width: 100%;
  padding: 26px 10px;
  display: flex;
  position: relative;
}

.team-header .team-detail-header-2 {
  flex-wrap: wrap;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 30px;
  max-width: 1140px;
  padding: 20px 0;
  display: flex;
  position: relative;
}

.team-header .image-2 {
  object-fit: cover;
  width: 170px;
  height: 160px;
  position: relative;
}

.team-header .team-detail-header-3 {
  flex-wrap: wrap;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 30px;
  min-width: 200px;
  display: flex;
  position: relative;
}

.team-header .team-detail-text {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  min-width: 150px;
  max-width: 400px;
  display: flex;
  position: relative;
}

.team-header .team-detail-text-2 {
  color: var(--themewhite);
  font-family: var(--theme-inter-bold-27-63-font-family);
  font-size: var(--theme-inter-bold-27-63-font-size);
  font-style: var(--theme-inter-bold-27-63-font-style);
  font-weight: var(--theme-inter-bold-27-63-font-weight);
  letter-spacing: var(--theme-inter-bold-27-63-letter-spacing);
  line-height: var(--theme-inter-bold-27-63-line-height);
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.team-header .team-detail-text-3 {
  color: var(--themewhite);
  letter-spacing: 0;
  height: 21px;
  font-family: Inter, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: Truepx;
  text-align: center;
  white-space: nowrap;
  align-self: stretch;
  position: relative;
}

.team-header .team-detail-header-4 {
  flex: none;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.team-header .team-detail-header-5 {
  object-fit: contain;
  width: 100px;
  height: 180px;
  position: relative;
}

.team-header .team-detail-wrapper {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.team-header .team-detail-2 {
  background-color: #fff;
  flex-direction: row;
  align-self: stretch;
  align-items: center;
  gap: 15px;
  width: 100%;
  height: 64px;
  padding: 0 0 0 16px;
  display: flex;
  position: relative;
}

.team-header .team-detail-3 {
  color: #000;
  letter-spacing: 1px;
  font-family: Inter, Helvetica;
  font-size: 13.8px;
  font-weight: 700;
  line-height: Truepx;
  white-space: nowrap;
  width: fit-content;
  text-decoration: underline;
  position: relative;
}

.team-header .team-detail-4 {
  color: #999fa1;
  font-family: var(--theme-inter-bold-13-75-upper-font-family);
  font-size: var(--theme-inter-bold-13-75-upper-font-size);
  font-style: var(--theme-inter-bold-13-75-upper-font-style);
  font-weight: var(--theme-inter-bold-13-75-upper-font-weight);
  letter-spacing: var(--theme-inter-bold-13-75-upper-letter-spacing);
  line-height: var(--theme-inter-bold-13-75-upper-line-height);
  white-space: nowrap;
  align-content: center;
  width: fit-content;
  position: relative;
}

.team-header .team-detail-5 {
  color: #999fa1;
  font-family: var(--theme-inter-bold-13-75-upper-font-family);
  font-size: var(--theme-inter-bold-13-75-upper-font-size);
  font-style: var(--theme-inter-bold-13-75-upper-font-style);
  font-weight: var(--theme-inter-bold-13-75-upper-font-weight);
  letter-spacing: var(--theme-inter-bold-13-75-upper-letter-spacing);
  line-height: var(--theme-inter-bold-13-75-upper-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-right: -122px;
  position: relative;
}

.team-detail-scroll {
  -webkit-overflow-scrolling: touch;
  gap: 15px;
  max-width: 100%;
  padding: 10px 0;
  display: flex;
  overflow-x: auto;
}

.team-detail-scroll::-webkit-scrollbar-thumb {
  background: #999fa1;
  border-radius: 3px;
}

.team-detail-scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.secTitle {
  color: #000;
  font-size: 18px;
  font-family: var(--theme-inter-bold-13-75-upper-font-family);
  padding: 30px 2px;
  font-weight: bold;
}

.element-team-detail {
  background-color: #f5f5f5;
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
}

.page-content {
  justify-items: center;
  max-width: 1200px;
}

.element-team-detail .instance-node-12 {
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
}

.element-team-detail .navigation-desktop-23 {
  margin-bottom: -853.42px !important;
  margin-left: -13408px !important;
  margin-right: unset !important;
}

.element-team-detail .navigation-desktop-24 {
  margin-left: unset !important;
}

.element-team-detail .navigation-desktop-25 {
  margin-bottom: -855.42px !important;
  margin-left: -13408px !important;
  margin-right: unset !important;
}

.element-team-detail .navigation-desktop-26 {
  margin-bottom: -853.42px !important;
  margin-left: -13472px !important;
  margin-right: unset !important;
}

.element-team-detail .navigation-desktop-27 {
  margin-bottom: -855.42px !important;
  margin-left: -13472px !important;
  margin-right: unset !important;
}

.element-team-detail .navigation-desktop-28 {
  margin-bottom: -853.42px !important;
  margin-left: -13536px !important;
  margin-right: unset !important;
}

.element-team-detail .navigation-desktop-29 {
  margin-bottom: -855.42px !important;
  margin-left: -13536px !important;
  margin-right: unset !important;
}

.element-team-detail .team-detail-top {
  align-self: stretch;
  width: 100%;
  position: relative;
}

.element-team-detail .team-detail {
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.element-team-detail .team-detail-header {
  background-color: var(--themetorch-red);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.element-team-detail .team-detail-header-wrapper {
  background-color: #34383c;
  flex-wrap: wrap;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 0;
  width: 100%;
  padding: 26px 10px;
  display: flex;
  position: relative;
}

.element-team-detail .team-detail-header-2 {
  flex-wrap: wrap;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 30px;
  max-width: 1140px;
  padding: 20px 0;
  display: flex;
  position: relative;
}

.element-team-detail .image-13 {
  object-fit: cover;
  width: 170px;
  height: 160px;
  position: relative;
}

.element-team-detail .team-detail-header-3 {
  flex-wrap: wrap;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 50px;
  min-width: 200px;
  display: flex;
  position: relative;
}

.element-team-detail .team-detail-text {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  min-width: 150px;
  max-width: 400px;
  display: flex;
  position: relative;
}

.element-team-detail .team-detail-text-2 {
  color: var(--themewhite);
  font-family: var(--theme-inter-bold-27-63-font-family);
  font-size: var(--theme-inter-bold-27-63-font-size);
  font-style: var(--theme-inter-bold-27-63-font-style);
  font-weight: var(--theme-inter-bold-27-63-font-weight);
  letter-spacing: var(--theme-inter-bold-27-63-letter-spacing);
  line-height: var(--theme-inter-bold-27-63-line-height);
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.element-team-detail .team-detail-text-3 {
  color: var(--themewhite);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  align-self: stretch;
  height: 21px;
  font-family: Inter, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  position: relative;
}

.element-team-detail .team-detail-header-4 {
  flex: none;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.element-team-detail .team-detail-header-5 {
  width: 100px;
  height: 180px;
  position: relative;
}

.element-team-detail .team-detail-2 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.element-team-detail .team-detail-3 {
  background-color: #fff;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 15px;
  width: 100%;
  padding: 0 0 0 16px;
  display: flex;
  position: relative;
}

.element-team-detail .team-detail-4 {
  color: #919191;
  letter-spacing: 1px;
  white-space: nowrap;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 13.8px;
  font-weight: 700;
  line-height: 24px;
  text-decoration: underline;
  position: relative;
}

.element-team-detail .team-detail-5 {
  color: #999fa1;
  font-family: var(--theme-inter-bold-13-75-upper-font-family);
  font-size: var(--theme-inter-bold-13-75-upper-font-size);
  font-style: var(--theme-inter-bold-13-75-upper-font-style);
  font-weight: var(--theme-inter-bold-13-75-upper-font-weight);
  letter-spacing: var(--theme-inter-bold-13-75-upper-letter-spacing);
  line-height: var(--theme-inter-bold-13-75-upper-line-height);
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.element-team-detail .team-detail-6 {
  color: #999fa1;
  font-family: var(--theme-inter-bold-13-75-upper-font-family);
  font-size: var(--theme-inter-bold-13-75-upper-font-size);
  font-style: var(--theme-inter-bold-13-75-upper-font-style);
  font-weight: var(--theme-inter-bold-13-75-upper-font-weight);
  letter-spacing: var(--theme-inter-bold-13-75-upper-letter-spacing);
  line-height: var(--theme-inter-bold-13-75-upper-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-right: -122px;
  position: relative;
}

.element-team-detail .page-content-15 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 40px;
  width: 100%;
  padding: 60px 16px;
  display: flex;
  position: relative;
}

.element-team-detail .page-content-section-3 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 30px;
  width: 100%;
  display: flex;
  position: relative;
}

.element-team-detail .page-content-16 {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 0 32px;
  display: flex;
  position: relative;
}

.element-team-detail .page-content-17 {
  color: var(--themeswamp);
  font-family: var(--theme-inter-bold-27-63-upper-font-family);
  font-size: var(--theme-inter-bold-27-63-upper-font-size);
  font-style: var(--theme-inter-bold-27-63-upper-font-style);
  font-weight: var(--theme-inter-bold-27-63-upper-font-weight);
  letter-spacing: var(--theme-inter-bold-27-63-upper-letter-spacing);
  line-height: var(--theme-inter-bold-27-63-upper-line-height);
  text-align: center;
  flex: 1;
  margin-top: -1px;
  position: relative;
}

.element-team-detail .team-detail-squad-12 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 50px;
  width: 100%;
  display: flex;
  position: relative;
}

.element-team-detail .team-detail-squad-13 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 15px;
  width: 100%;
  display: flex;
  position: relative;
}

.element-team-detail .row-header {
  color: var(--themeouter-space);
  font-family: var(--theme-inter-bold-15-89-upper-font-family);
  font-size: var(--theme-inter-bold-15-89-upper-font-size);
  font-style: var(--theme-inter-bold-15-89-upper-font-style);
  font-weight: var(--theme-inter-bold-15-89-upper-font-weight);
  letter-spacing: var(--theme-inter-bold-15-89-upper-letter-spacing);
  line-height: var(--theme-inter-bold-15-89-upper-line-height);
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.element-team-detail .team-detail-squad-14 {
  flex-wrap: wrap;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  display: flex;
  position: relative;
}

.element-team-detail .team-detail-squad-cell {
  margin-left: -1px !important;
  margin-right: -1px !important;
}

.element-team-detail .team-detail-squad-15 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 15px;
  width: 100%;
  display: flex;
  position: relative;
}

.element-team-detail .page-content-section-4 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  display: flex;
  position: relative;
}

.element-team-detail .club-stats-container-wrapper {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.element-team-detail .club-stats-content-wrapper {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  max-width: 1140px;
  display: flex;
  position: relative;
}

.element-team-detail .club-stats-content-2 {
  flex-wrap: wrap;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 5px 10px;
  width: 100%;
  padding: 20px 0;
  display: flex;
  position: relative;
}

.element-team-detail .stat-cell-2 {
  margin-left: -11px !important;
  margin-right: -11px !important;
}

.element-team-detail .livescore-header-2 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  max-width: 1400px;
  display: flex;
  position: relative;
}

.element-team-detail .gameday-4 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  display: flex;
  position: relative;
}

.element-team-detail .gameday-header-3 {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.element-team-detail .gameday-header-date-3 {
  color: var(--themeouter-space);
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 13.9px;
  font-weight: 400;
  line-height: 20px;
  position: relative;
}

.element-team-detail .text-wrapper-99 {
  font-family: var(--theme-inter-bold-13-89-font-family);
  font-size: var(--theme-inter-bold-13-89-font-size);
  font-style: var(--theme-inter-bold-13-89-font-style);
  font-weight: var(--theme-inter-bold-13-89-font-weight);
  letter-spacing: var(--theme-inter-bold-13-89-letter-spacing);
  line-height: var(--theme-inter-bold-13-89-line-height);
}

.element-team-detail .text-wrapper-100 {
  color: #34383c;
  font-family: var(--theme-inter-regular-13-89-font-family);
  font-size: var(--theme-inter-regular-13-89-font-size);
  font-style: var(--theme-inter-regular-13-89-font-style);
  font-weight: var(--theme-inter-regular-13-89-font-weight);
  letter-spacing: var(--theme-inter-regular-13-89-letter-spacing);
  line-height: var(--theme-inter-regular-13-89-line-height);
}

.element-team-detail .gameday-header-time-3 {
  color: var(--themeouter-space);
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 13.7px;
  font-weight: 400;
  line-height: 20px;
  position: relative;
}

.element-team-detail .text-wrapper-101 {
  font-family: var(--theme-inter-bold-13-67-font-family);
  font-size: var(--theme-inter-bold-13-67-font-size);
  font-style: var(--theme-inter-bold-13-67-font-style);
  font-weight: var(--theme-inter-bold-13-67-font-weight);
  letter-spacing: var(--theme-inter-bold-13-67-letter-spacing);
  line-height: var(--theme-inter-bold-13-67-line-height);
}

.element-team-detail .text-wrapper-102 {
  color: #34383c;
  font-family: var(--theme-inter-regular-13-67-font-family);
  font-size: var(--theme-inter-regular-13-67-font-size);
  font-style: var(--theme-inter-regular-13-67-font-style);
  font-weight: var(--theme-inter-regular-13-67-font-weight);
  letter-spacing: var(--theme-inter-regular-13-67-letter-spacing);
  line-height: var(--theme-inter-regular-13-67-line-height);
}

.element-team-detail .fixture-data-cell-4 {
  margin-left: unset !important;
  margin-right: unset !important;
}

.element-team-detail .action-button-6 {
  flex: none !important;
}

.element-team-detail .action-button-7 {
  align-self: unset !important;
  height: 48px !important;
}

.element-team-detail .news-5 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 25px;
  width: 100%;
  max-width: 1200px;
  display: flex;
  position: relative;
}

.element-team-detail .news-container-4 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 24px;
  width: 100%;
  display: flex;
  position: relative;
}

.element-team-detail .news-container-5 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.element-team-detail .title-8 {
  color: var(--themeswamp);
  font-family: var(--theme-inter-bold-16-03-upper-font-family);
  font-size: var(--theme-inter-bold-16-03-upper-font-size);
  font-style: var(--theme-inter-bold-16-03-upper-font-style);
  font-weight: var(--theme-inter-bold-16-03-upper-font-weight);
  letter-spacing: var(--theme-inter-bold-16-03-upper-letter-spacing);
  line-height: var(--theme-inter-bold-16-03-upper-line-height);
  flex: 1;
  margin-top: -1px;
  position: relative;
}

.element-team-detail .news-container-grid-5 {
  flex-wrap: wrap;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  display: flex;
  position: relative;
}

.element-team-detail .news-article-2 {
  margin-right: -2px !important;
}

.element-team-detail .news-article-3 {
  width: unset !important;
  flex: 1 !important;
}

.element-team-detail .footer-26 {
  background-color: #0000 !important;
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
}

.element-team-detail .footer-27 {
  margin-left: -324px !important;
}

.element-team-detail .team-detail-wrapper {
  background-color: #fff;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  display: flex;
  position: relative;
}

.element-team-detail .team-detail-header-6 {
  background-color: #34383c;
  flex-wrap: wrap;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 0;
  width: 100%;
  padding: 26px 150px;
  display: flex;
  position: relative;
}

.element-team-detail .team-detail-header-7 {
  flex: 1;
  align-items: center;
  gap: 30px;
  max-width: 1140px;
  height: 200px;
  padding: 20px 0;
  display: flex;
  position: relative;
}

.element-team-detail .image-14 {
  object-fit: cover;
  align-self: stretch;
  width: 160px;
  position: relative;
}

.element-team-detail .team-detail-header-8 {
  flex-wrap: wrap;
  flex: 1;
  align-items: center;
  gap: 30px;
  margin-top: -10px;
  margin-bottom: -10px;
  display: flex;
  position: relative;
}

.element-team-detail .team-detail-text-4 {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  max-width: 400px;
  display: flex;
  position: relative;
}

.element-team-detail .team-detail-text-5 {
  color: var(--themewhite);
  font-family: var(--theme-inter-bold-27-63-font-family);
  font-size: var(--theme-inter-bold-27-63-font-size);
  font-style: var(--theme-inter-bold-27-63-font-style);
  font-weight: var(--theme-inter-bold-27-63-font-weight);
  letter-spacing: var(--theme-inter-bold-27-63-letter-spacing);
  line-height: var(--theme-inter-bold-27-63-line-height);
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.element-team-detail .team-detail-text-6 {
  color: var(--themewhite);
  letter-spacing: 0;
  white-space: nowrap;
  align-self: stretch;
  height: 21px;
  font-family: Inter, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  position: relative;
}

.element-team-detail .content-frame-wrapper {
  background-color: #fff;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.element-team-detail .div-wrapper-4 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  display: flex;
  position: relative;
}

.element-team-detail .team-detail-7 {
  background-color: #fff;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 15px;
  width: 100%;
  max-width: 1200px;
  height: 64px;
  padding: 0 150px;
  display: flex;
  position: relative;
}

.element-team-detail .page-content-framer-3 {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  display: flex;
  position: relative;
}

.element-team-detail .page-content-18 {
  flex-direction: column;
  flex: 1;
  align-items: center;
  gap: 40px;
  padding: 60px 32px;
  display: flex;
  position: relative;
}

.element-team-detail .page-content-section-5 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 30px;
  width: 1136px;
  display: flex;
  position: relative;
}

.element-team-detail .team-detail-squad-16 {
  flex-wrap: wrap;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  display: flex;
  position: relative;
}

.element-team-detail .stat-cell-3 {
  min-width: 300px !important;
  width: unset !important;
  flex: 1 !important;
}

.element-team-detail .page-content-section-6 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 30px;
  width: 1136px;
  display: flex;
  position: relative;
}

.element-team-detail .news-6 {
  flex-direction: column;
  flex: 1;
  align-items: center;
  gap: 25px;
  max-width: 1200px;
  display: flex;
  position: relative;
}

.element-team-detail .news-container-grid-6 {
  flex-wrap: wrap;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  display: flex;
  position: relative;
}

.news-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 16px;
}

.news-grid {
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  gap: 20px;
  padding: 20px 0;
  display: grid;
}

@media (width <= 768px) {
  .news-grid {
    grid-template-columns: 1fr;
  }
}

.secTitle {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
  font-size: 1.5rem;
  font-weight: bold;
}

.team-squad {
  grid-template-columns: 1fr;
  gap: 3px;
  width: 100%;
  max-width: 1200px;
  display: grid;
}

.stats-grid {
  grid-template-columns: 1fr;
  gap: 5px;
  width: 100%;
  max-width: 1200px;
  display: grid;
}

@media (width >= 600px) {
  .stats-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (width >= 1024px) {
  .stats-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (width >= 600px) {
  .team-squad {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (width >= 1024px) {
  .team-squad {
    grid-template-columns: repeat(3, 1fr);
  }
}

.flagicon {
  object-fit: cover;
  width: 32px;
  height: 20px;
}

.property-desktop-wrapper {
  background-color: var(--themewhite);
  border-color: #e9ebed;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  padding: 9.14px 16px 10.16px;
  display: flex;
  position: relative;
}

.property-desktop-wrapper .left-container-4 {
  flex: none;
  align-items: center;
  gap: 5px;
  display: inline-flex;
  position: relative;
}

.property-desktop-wrapper .image-7 {
  background-position: 50%;
  background-size: cover;
  width: 35px;
  height: 35px;
  position: relative;
}

.property-desktop-wrapper .name-4 {
  color: var(--themeswamp);
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 13.9px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
}

.property-desktop-wrapper .text-wrapper-13 {
  color: #000e14;
  letter-spacing: 0;
  font-family: Inter, Helvetica;
  font-size: 13.9px;
  font-weight: 400;
  line-height: 24px;
}

.property-desktop-wrapper .text-wrapper-14 {
  font-weight: 700;
}

.property-desktop-wrapper .transfer-details {
  flex: none;
  align-items: center;
  position: relative;
}

.property-desktop-wrapper .div-4 {
  flex: none;
  align-items: center;
  gap: 10px;
  max-width: 369.33px;
  display: inline-flex;
  position: relative;
}

.property-desktop-wrapper .team-logo-2 {
  justify-content: flex-end;
  align-items: center;
  width: 24px;
  display: flex;
  position: relative;
}

.property-desktop-wrapper .image-8 {
  background-position: 50%;
  background-size: cover;
  flex: 1;
  height: 24px;
  position: relative;
}

.property-desktop-wrapper .team-name-wrapper {
  flex: none;
  justify-content: flex-end;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.property-desktop-wrapper .team-name-3 {
  color: var(--themeswamp);
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 13.7px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
}

.property-desktop-wrapper .icn-arrow-right {
  width: 12px;
  height: 12px;
  position: relative;
}

.property-desktop-wrapper .image-9 {
  background-position: 50%;
  background-size: cover;
  flex: 1;
  height: 24px;
  position: relative;
}

.property-desktop-wrapper.property-1-2-desktop {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 25px;
  width: 1149px;
}

.property-desktop-wrapper.property-1-2-mobile {
  flex-direction: column;
  align-items: flex-start;
  gap: 25px;
  width: 390px;
}

.property-desktop-wrapper.property-1-2-desktop .transfer-details {
  gap: 25px;
  display: inline-flex;
}

.property-desktop-wrapper.property-1-2-mobile .transfer-details {
  flex-wrap: wrap;
  align-self: stretch;
  gap: 10px;
  width: 100%;
  display: flex;
}

.element-transfers-desktop {
  background-color: #f5f5f5;
  flex-direction: column;
  align-items: center;
  gap: 60px;
  display: flex;
  position: relative;
}

.element-transfers-desktop .instance-node-9 {
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
}

.element-transfers-desktop .page-control-3 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  padding: 0 32px;
  display: flex;
  position: relative;
}

.element-transfers-desktop .single-transfer-2 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  display: flex;
  position: relative;
}

.element-transfers-desktop .footer-21 {
  background-color: #0000 !important;
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
}

.element-transfers-desktop .footer-22 {
  margin-left: -324px !important;
}

.element-news-mobile {
  background-color: #f5f5f5;
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
}

.element-news-mobile .instance-node-6 {
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
}

.element-news-mobile .page-content-6 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 60px 16px;
  display: flex;
  position: relative;
}

.element-news-mobile .news {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 25px;
  width: 100%;
  max-width: 1200px;
  display: flex;
  position: relative;
}

.element-news-mobile .news-container {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 24px;
  width: 100%;
  display: flex;
  position: relative;
}

.element-news-mobile .news-container-grid {
  flex-wrap: wrap;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  display: flex;
  position: relative;
}

.element-news-mobile .news-article-instance {
  margin-right: -2px !important;
}

.element-news-mobile .action-button-instance {
  flex: none !important;
}

.element-news-mobile .action-button-2 {
  align-self: unset !important;
  height: 48px !important;
}

.element-news-mobile .footer-9 {
  background-color: #0000 !important;
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
}

.element-news-mobile .footer-10 {
  margin-left: -324px !important;
}

.element-news-mobile .page-frame {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  display: flex;
  position: relative;
}

.element-news-mobile .page-content-7 {
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  display: flex;
  position: relative;
}

.element-news-mobile .page-content-8 {
  flex-direction: column;
  flex: 1;
  align-items: center;
  gap: 10px;
  padding: 60px 16px;
  display: flex;
  position: relative;
}

.element-news-mobile .page-header-instance-wrapper {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  display: flex;
  position: relative;
}

.element-news-mobile .page-header-instance {
  flex: none !important;
  width: 868px !important;
}

.element-news-mobile .news-2 {
  flex-direction: column;
  flex: 1;
  align-items: center;
  gap: 25px;
  max-width: 1200px;
  display: flex;
  position: relative;
}

.element-news-mobile .news-container-grid-2 {
  flex-wrap: wrap;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  display: flex;
  position: relative;
}

.element-homepage-desktop {
  background-color: #f5f5f5;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.element-homepage-desktop .design-component-instance-node {
  flex: none !important;
  width: 100% !important;
}

.element-homepage-desktop .content-frame-6 {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  display: flex;
  position: relative;
}

.element-homepage-desktop .page-content-22 {
  flex-direction: column;
  flex: 1;
  align-items: center;
  gap: 20px;
  max-width: 1200px;
  padding: 0 32px;
  display: flex;
  position: relative;
}

.element-homepage-desktop .div-17 {
  scroll-behavior: auto;
  -ms-scroll-rails: railed;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  width: 100%;
  max-width: 1200px;
  padding-left: 12px;
  display: flex;
  position: relative;
}

.element-homepage-desktop .matchup-row-list {
  white-space: nowrap;
  scrollbar-width: none;
  -ms-overflow-style: none;
  gap: 12px;
  width: -webkit-fill-available;
  padding-bottom: 10px;
  display: flex;
  overflow-x: auto;
}

.element-homepage-desktop .matchup-row-list::-webkit-scrollbar {
  display: none;
}

.element-homepage-desktop .matchup-cell-instance {
  height: unset !important;
  flex: none !important;
  align-self: stretch !important;
}

.element-homepage-desktop .matchup-cell-2 {
  height: unset !important;
  flex: none !important;
  align-self: stretch !important;
  margin-left: -1px !important;
}

.element-homepage-desktop .hero-instance {
  width: 100% !important;
}

.element-homepage-desktop .club-carousel-header {
  flex: none;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 0 20px;
  display: flex;
  position: relative;
}

.element-homepage-desktop .club-carousel-title {
  color: var(--themeswamp);
  font-family: var(--theme-inter-bold-15-33-upper-font-family);
  font-size: var(--theme-inter-bold-15-33-upper-font-size);
  font-style: var(--theme-inter-bold-15-33-upper-font-style);
  font-weight: var(--theme-inter-bold-15-33-upper-font-weight);
  letter-spacing: var(--theme-inter-bold-15-33-upper-letter-spacing);
  line-height: var(--theme-inter-bold-15-33-upper-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.element-homepage-desktop .club-carousel-action {
  color: var(--themeswamp);
  font-family: var(--theme-inter-bold-10-97-upper-font-family);
  font-size: var(--theme-inter-bold-10-97-upper-font-size);
  font-style: var(--theme-inter-bold-10-97-upper-font-style);
  font-weight: var(--theme-inter-bold-10-97-upper-font-weight);
  letter-spacing: var(--theme-inter-bold-10-97-upper-letter-spacing);
  line-height: var(--theme-inter-bold-10-97-upper-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.element-homepage-desktop .club-carousel-list {
  white-space: nowrap;
  scrollbar-width: none;
  -ms-overflow-style: none;
  align-items: center;
  gap: 13px;
  width: -webkit-fill-available;
  height: 146px;
  display: flex;
  overflow-x: auto;
}

.element-homepage-desktop .club-carousel-list::-webkit-scrollbar {
  display: none;
}

.element-homepage-desktop .club-cell-instance {
  margin-top: -275.92px !important;
  margin-right: -2971px !important;
}

.element-homepage-desktop .club-cell-2 {
  margin-top: -275.92px !important;
  margin-right: -2858px !important;
}

.element-homepage-desktop .action-cell-2 {
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  gap: 22px;
  width: 100%;
  display: grid;
  position: relative;
}

@media (width <= 430px) {
  .element-homepage-desktop .action-cell-2 {
    grid-template-columns: 1fr;
  }
}

.element-homepage-desktop .action-cell-instance {
  flex-grow: 1;
  width: 100%;
  min-width: 320px;
}

.element-homepage-desktop .banner {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  max-width: 1200px;
  display: flex;
  position: relative;
}

.element-homepage-desktop .banner-wrapper {
  background-color: #0003;
  background-image: url("banner-wrapper.6b830073.png");
  background-position: 50%;
  background-size: cover;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 313px;
  padding: 32px;
  display: flex;
  position: relative;
}

.element-homepage-desktop .banner-content {
  flex-wrap: wrap;
  flex: none;
  justify-content: space-around;
  align-self: stretch;
  align-items: flex-end;
  gap: 48px 375px;
  width: 100%;
  padding: 0 32px;
  display: flex;
  position: relative;
}

.element-homepage-desktop .banner-content-left {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 34px;
  max-width: 500px;
  display: flex;
  position: relative;
}

.element-homepage-desktop .title-14 {
  color: #fff;
  letter-spacing: 0;
  align-self: stretch;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 44px;
  font-weight: 700;
  line-height: 49px;
  position: relative;
}

.element-homepage-desktop .button-text-wrapper {
  background-color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 169px;
  height: 41px;
  display: flex;
  position: relative;
}

.button-text {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 11px;
  font-weight: 700;
  line-height: 20px;
  position: relative;
}

.news-7 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 25px;
  width: 100%;
  max-width: 1200px;
  display: flex;
  position: relative;
}

.news-container-6 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 24px;
  width: 100%;
  display: flex;
  position: relative;
}

.page-content-23 {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 0 32px;
  display: flex;
  position: relative;
}

.page-content-24 {
  color: var(--themeswamp);
  font-family: var(--theme-inter-bold-27-63-upper-font-family);
  font-size: var(--theme-inter-bold-27-63-upper-font-size);
  font-style: var(--theme-inter-bold-27-63-upper-font-style);
  font-weight: var(--theme-inter-bold-27-63-upper-font-weight);
  letter-spacing: var(--theme-inter-bold-27-63-upper-letter-spacing);
  line-height: var(--theme-inter-bold-27-63-upper-line-height);
  text-align: center;
  flex: 1;
  margin-top: -1px;
  position: relative;
}

.news-container-grid-7 {
  flex-wrap: wrap;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  display: flex;
  position: relative;
}

.news-article-4 {
  width: 343px !important;
  max-width: 345px !important;
}

.action-button-10 {
  flex: none !important;
}

.action-button-11 {
  align-self: unset !important;
  height: 48px !important;
}

.footer-36 {
  background-color: #0000 !important;
  flex: none !important;
  width: 100% !important;
}

@media (width <= 800px) {
  .news-container-grid-7 {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (width >= 801px) {
  .news-container-grid-7 {
    grid-template-columns: repeat(3, 1fr);
  }
}

.news-container-grid-7 {
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  justify-content: center;
  gap: 20px;
  width: 100%;
  display: grid;
}

.action-button {
  justify-content: center;
  align-items: flex-start;
  width: 180px;
  max-width: 180px;
  display: flex;
  position: relative;
}

.action-button .button-title-wrapper {
  cursor: pointer;
  background-color: #000;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  padding: 10px 16px;
  display: flex;
  position: relative;
}

.action-button .button-title {
  color: var(--themewhite);
  font-family: var(--theme-inter-bold-11-58-upper-font-family);
  font-size: var(--theme-inter-bold-11-58-upper-font-size);
  font-style: var(--theme-inter-bold-11-58-upper-font-style);
  font-weight: var(--theme-inter-bold-11-58-upper-font-weight);
  letter-spacing: var(--theme-inter-bold-11-58-upper-letter-spacing);
  line-height: var(--theme-inter-bold-11-58-upper-line-height);
  text-align: center;
  flex: 1;
  width: 77.03px;
  margin-top: -1px;
  position: relative;
}

.transfer {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
  max-width: 1200px;
  display: flex;
  position: relative;
}

.transfer .player-bio {
  cursor: pointer;
  flex-direction: column;
  align-items: flex-start;
  max-width: 369.33px;
  height: 180px;
  display: inline-flex;
  position: relative;
}

.transfer .card {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  width: 345.33px;
  display: flex;
  position: relative;
}

.transfer .background-shadow {
  background-color: var(--themewhite);
  flex-direction: column;
  flex: none;
  justify-content: flex-end;
  align-self: stretch;
  align-items: center;
  gap: 49.16px;
  width: 100%;
  min-height: 180px;
  display: flex;
  position: relative;
  box-shadow: 0 2px 8px #000e140d;
}

.transfer .player-detail-wrapper {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 151px;
  display: inline-flex;
  position: absolute;
  top: 29px;
  left: 210px;
}

.transfer .player-detail {
  align-self: stretch;
  width: 100%;
  height: 147px;
  position: relative;
}

.transfer .card-data {
  flex: none;
  align-items: flex-end;
  width: 345.33px;
  padding: 1px 17px 9px;
  display: flex;
  position: relative;
}

.transfer .card-data-wrapper {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.transfer .logo-5 {
  background-color: #fff;
  background-image: url("logo-3.492ead9c.png");
  background-position: 50%;
  background-size: cover;
  width: 30px;
  height: 30px;
  padding: 4px;
  position: relative;
}

.transfer .content-2 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  display: flex;
  position: relative;
}

.transfer .div-3 {
  color: var(--themeblack);
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
}

.transfer .text-wrapper-9 {
  color: #000;
  letter-spacing: 0;
  font-family: Inter, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.transfer .text-wrapper-10 {
  font-weight: 700;
}

.transfer .subtitle-2 {
  color: var(--themeswamp);
  letter-spacing: 1px;
  text-align: center;
  max-width: 500px;
  font-family: Inter, Helvetica;
  font-size: 15.8px;
  font-weight: 400;
  line-height: 25px;
  position: relative;
}

.transfer .text-wrapper-11 {
  letter-spacing: .16px;
  font-weight: 700;
  text-decoration: underline;
}

.transfer .text-wrapper-12 {
  letter-spacing: .16px;
  font-weight: 500;
}

.transfer .buttons {
  flex: none;
  align-items: flex-start;
  gap: 10px;
  display: inline-flex;
  position: relative;
}

.transfer .action-button-instance {
  align-self: unset !important;
  height: 48px !important;
}

.element-transfer {
  background-color: #f5f5f5;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 1000px;
  display: flex;
  position: relative;
}

.element-transfer .navigation-instance {
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
}

.element-transfer .transfer-confirmation {
  flex: none !important;
}

.element-transfer .footer-4 {
  background-color: #0000 !important;
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
}

.paragraph-wrapper {
  flex-direction: column;
  align-items: flex-start;
  gap: 19px;
  width: 100%;
  display: flex;
  position: relative;
}

.paragraph-wrapper .div-wrapper {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.paragraph-wrapper .text-wrapper-2 {
  color: var(--themeouter-space);
  font-family: var(--theme-inter-bold-16-font-family);
  font-size: var(--theme-inter-bold-16-font-size);
  font-style: var(--theme-inter-bold-16-font-style);
  font-weight: var(--theme-inter-bold-16-font-weight);
  letter-spacing: var(--theme-inter-bold-16-letter-spacing);
  line-height: var(--theme-inter-bold-16-line-height);
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.paragraph-wrapper .p {
  color: var(--themeouter-space);
  font-family: var(--theme-inter-regular-15-13-font-family);
  font-size: var(--theme-inter-regular-15-13-font-size);
  font-style: var(--theme-inter-regular-15-13-font-style);
  font-weight: var(--theme-inter-regular-15-13-font-weight);
  letter-spacing: var(--theme-inter-regular-15-13-letter-spacing);
  line-height: var(--theme-inter-regular-15-13-line-height);
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.element-ligaordnung {
  background-color: #f5f5f5;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  display: flex;
  position: relative;
}

.element-ligaordnung .design-component-instance-node {
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
}

.element-ligaordnung .content-frame {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  display: flex;
  position: relative;
}

.element-ligaordnung .page-content {
  flex-direction: column;
  flex: 1;
  align-items: center;
  gap: 20px;
  max-width: 1200px;
  display: flex;
  position: relative;
}

.element-ligaordnung .terms {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 32px;
  width: 100%;
  padding: 0 32px 60px;
  display: flex;
  position: relative;
}

.element-ligaordnung .terms-header-wrapper {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
  display: flex;
  position: relative;
}

.element-ligaordnung .title-3 {
  color: var(--themeswamp);
  font-family: var(--theme-inter-black-37-03-font-family);
  font-size: var(--theme-inter-black-37-03-font-size);
  font-style: var(--theme-inter-black-37-03-font-style);
  font-weight: var(--theme-inter-black-37-03-font-weight);
  letter-spacing: var(--theme-inter-black-37-03-letter-spacing);
  line-height: var(--theme-inter-black-37-03-line-height);
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.element-ligaordnung .div-wrapper-2 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.element-ligaordnung .text-wrapper-13 {
  color: var(--themeouter-space);
  font-family: var(--theme-inter-bold-15-75-font-family);
  font-size: var(--theme-inter-bold-15-75-font-size);
  font-style: var(--theme-inter-bold-15-75-font-style);
  font-weight: var(--theme-inter-bold-15-75-font-weight);
  letter-spacing: var(--theme-inter-bold-15-75-letter-spacing);
  line-height: var(--theme-inter-bold-15-75-line-height);
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.element-ligaordnung .paragraph-wrapper-2 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 19px;
  width: 100%;
  display: flex;
  position: relative;
}

.element-ligaordnung .paragraph-wrapper-3 {
  color: var(--themeouter-space);
  font-family: var(--theme-inter-bold-16-font-family);
  font-size: var(--theme-inter-bold-16-font-size);
  font-style: var(--theme-inter-bold-16-font-style);
  font-weight: var(--theme-inter-bold-16-font-weight);
  letter-spacing: var(--theme-inter-bold-16-letter-spacing);
  line-height: var(--theme-inter-bold-16-line-height);
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.element-ligaordnung .paragraph-wrapper-4 {
  color: var(--themeouter-space);
  font-family: var(--theme-inter-regular-15-13-font-family);
  font-size: var(--theme-inter-regular-15-13-font-size);
  font-style: var(--theme-inter-regular-15-13-font-style);
  font-weight: var(--theme-inter-regular-15-13-font-weight);
  letter-spacing: var(--theme-inter-regular-15-13-letter-spacing);
  line-height: var(--theme-inter-regular-15-13-line-height);
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.element-ligaordnung .footer-instance {
  background-color: #0000 !important;
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
}

.table-of-contents {
  border-radius: 8px;
  align-self: stretch;
  justify-items: left;
  margin-bottom: 24px;
}

.toc-title {
  margin-bottom: 8px;
  font-size: 18px;
  font-weight: bold;
}

.toc-link {
  text-align: left;
  cursor: pointer;
  background: none;
  border: none;
  padding: 8px 0;
  font-size: 16px;
  text-decoration: underline;
}

.paragraph-section {
  width: 100%;
  margin-top: 40px;
  padding-top: 10px;
}
/*# sourceMappingURL=index.a22fea77.css.map */
