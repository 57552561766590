.element-leaderboard {
  align-items: center;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  gap: 60px;
  position: relative;
}

.element-leaderboard .instance-node-10 {
  align-self: stretch !important;
  flex: 0 0 auto !important;
  width: 100% !important;
}

.element-leaderboard .page-control-4 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  padding: 0px 32px;
  position: relative;
  width: 100%;
}

.element-leaderboard .leaderboard {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  height: 56px;
  padding: 0px 0px 8px;
  position: relative;
  width: 100%;
}

.element-leaderboard .leaderboard-wrapper {
  align-items: flex-start;
  align-self: stretch;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  padding: 0px 8px 0px 0px;
  position: relative;
}

.element-leaderboard .leaderboard-2 {
  align-items: flex-end;
  display: inline-flex;
  flex: 1;
  flex-grow: 1;
  padding: 0px 8px;
  position: relative;
}

.element-leaderboard .leaderboard-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding: 8px 0px;
  position: relative;
  width: 44px;
}

.element-leaderboard .leaderboard-4 {
  color: #bababa;
  font-family: "Inter", Helvetica;
  font-size: 11.1px;
  font-weight: 700;
  letter-spacing: 1.00px;
  line-height: 18px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element-leaderboard .leaderboard-5 {
  align-items: flex-start;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-color: var(--themeswamp);
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.element-leaderboard .leaderboard-6 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 8px 0px;
  position: relative;
  width: 100%;
}

.element-leaderboard .leaderboard-7 {
  color: var(--themeswamp);
  font-family: "Inter", Helvetica;
  font-size: 11.5px;
  font-weight: 700;
  letter-spacing: 1.00px;
  line-height: 18px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element-leaderboard .leaderboard-highligh-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 10px 10px;
  position: relative;
  width: 100%;
}

.element-leaderboard .class-11 {
  flex: 0 0 auto !important;
  margin-right: -19.33px !important;
}

.element-leaderboard .class-12 {
  flex: 0 0 auto !important;
}

.element-leaderboard .single-stat-cells {
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: 100%;
}

.element-leaderboard .footer-23 {
  align-self: stretch !important;
  background-color: transparent !important;
  flex: 0 0 auto !important;
  width: 100% !important;
}

.element-leaderboard .footer-24 {
  margin-left: -324px !important;
}

.element-leaderboard .segButton {
  align-self: stretch;
  color: var(--themeswamp);
  font-family: var(--theme-inter-bold-15-75-upper-font-family);
  font-size: var(--theme-inter-bold-15-75-upper-font-size);
  font-style: var(--theme-inter-bold-15-75-upper-font-style);
  font-weight: var(--theme-inter-bold-15-75-upper-font-weight);
  letter-spacing: var(--theme-inter-bold-15-75-upper-letter-spacing);
  line-height: var(--theme-inter-bold-15-75-upper-line-height);
  position: relative;
  border: none;
  background-color: transparent;
}

.segButtonActive {
  align-self: stretch;
  color: var(--themeswamp);
  font-family: var(--theme-inter-bold-15-75-upper-font-family);
  font-size: var(--theme-inter-bold-15-75-upper-font-size);
  font-style: var(--theme-inter-bold-15-75-upper-font-style);
  font-weight: var(--theme-inter-bold-15-75-upper-font-weight);
  letter-spacing: var(--theme-inter-bold-15-75-upper-letter-spacing);
  line-height: var(--theme-inter-bold-15-75-upper-line-height);
  position: relative;
  border: none;
  background-color: transparent;
  text-decoration-line: underline;
}
